export const colorArr = [
  { id: "ner_time", color: "rgb(255, 127, 14)" },
  { id: "ner_people", color: "rgb(214, 39, 40)" },
  { id: "ner_place", color: "rgb(23, 190, 207)" },
  { id: "ner_style", color: "rgb(227, 119, 194)" },
  { id: "ner_scenes", color: "rgb(44, 160, 44)" },
  { id: "ner_model", color: "rgb(31, 119, 180)" },
  { id: "ner_design", color: "rgb(188, 189, 34)" },
  { id: "ner_effect", color: "rgb(148, 103, 189)" },
  { id: "ner_ip", color: "rgb(140, 86, 75)" },
  { id: "ner_color", color: "rgb(127, 127, 127)" },
  { id: "ner_package", color: "rgb(63, 103, 95)" },
  { id: "ner_type", color: "rgb(109, 236, 142)" },
  { id: "ner_size", color: "rgb(191, 60, 225)" },
  { id: "ner_food", color: "rgb(216, 74, 146)" },
  { id: "ner_brand", color: "rgb(32, 73, 177)" },
  { id: "ner_technology", color: "rgb(98, 238, 186)" },
  { id: "ner_figure", color: "rgb(201, 52, 147)" },
  { id: "tech_word", color: "rgb(47, 152, 142)" },
  { id: "ner_spokesperson", color: "rgb(172, 36, 68)" },
  { id: "ner_character", color: "rgb(181, 174, 105)" },
  { id: "ner_taste", color: "rgb(34, 102, 200)" },
  { id: "ner_material", color: "rgb(255, 189, 0)" },
  { id: "ner_shape", color: "rgb(100, 181, 198)" },
];
