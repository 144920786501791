<template>
  <div>
    <div class="change-password-panel">
      <div class="header">
        <span class="title">修改密码 （仅主手机号可用）</span>
      </div>
      <div class="button">
        <el-button type="primary"
                   @click="showDialogClick"
                   :disabled="!canChange">修改密码</el-button>
      </div>
    </div>
    <el-dialog :visible="showDialog"
               @close="closeDialogClick">
      <iframe id="login-ifm"
              style="width:400px;margin:0 auto;height:500px;display:block;"
              scrolling="no"
              frameborder="0"
              src="https://loginnew.mktindex.com/reg/change_password"></iframe>
    </el-dialog>
  </div>
</template>


<script>
export default {
  name: 'ChangePasswordPanel',
  data() {
    return {
      showDialog: false,
      canChange: false,
    }
  },
  watch: {
    '$store.state.user.loginInfo': {
      handler(newVal) {
        this.canChange = newVal.can_change_passwd
      },
    },
  },
  methods: {
    showDialogClick() {
      this.showDialog = true
    },
    closeDialogClick() {
      this.showDialog = false
    },
  },
  mounted() {
    if (this.$store.state.user.loginInfo) {
      this.canChange = this.$store.state.user.loginInfo.can_change_passwd
    }
  },
}
</script>

<style lang="less" >
.change-password-panel {
  padding: 48px;
  width: 100%;
  height: 300px;
  box-shadow: 0px 2px 5px 0px rgba(159, 77, 0, 0.2);
  border-radius: 4px 4px 4px 4px;
  background-color: white;
  font-family: MicrosoftYaHei;
  color: #252525;

  .header {
    .title {
      font-size: 16px;
      margin-bottom: 22px;
    }
  }
}
</style>
