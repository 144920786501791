// core function
function Bubbles(container, self, options) {
  // options
  options = typeof options !== "undefined" ? options : {};
  animationTime = options.animationTime || 200; // how long it takes to animate chat bubble, also set in CSS
  typeSpeed = options.typeSpeed || 5; // delay per character, to simulate the machine "typing"
  widerBy = options.widerBy || 2; // add a little extra width to bubbles to make sure they don't break
  fontSizeBy = options.fontSizeBy || 14; // add a little extra width to bubbles to make sure they don't break
  sidePadding = options.sidePadding || 6; // padding on both sides of chat bubbles
  recallInteractions = options.recallInteractions || 0; // number of interactions to be remembered and brought back upon restart
  inputCallbackFn = options.inputCallbackFn || false; // should we display an input field?
  responseCallbackFn = options.responseCallbackFn || false; // is there a callback function for when a user clicks on a bubble button
  copyCallbackFn = options.copyCallbackFn || false;
  defaultQuestionCallbackFn = options.defaultQuestionCallbackFn || false;
  replyCallbackFn = options.replyCallbackFn || false;
  var standingAnswer = "robot"; // remember where to restart convo if interrupted
  var isLock = false;
  var _convo = {}; // local memory for conversation JSON object
  //--> NOTE that this object is only assigned once, per session and does not change for this
  // 		constructor name during open session.

  // local storage for recalling conversations upon restart
  var localStorageCheck = function () {
    var test = "chat-bubble-storage-test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (error) {
      console.error(
        "Your server does not allow storing data locally. Most likely it's because you've opened this page from your hard-drive. For testing you can disable your browser's security or start a localhost environment."
      );
      return false;
    }
  };
  var localStorageAvailable = localStorageCheck() && recallInteractions > 0;
  var interactionsLS = "chat-bubble-interactions";
  var interactionsHistory =
    (localStorageAvailable &&
      JSON.parse(localStorage.getItem(interactionsLS))) ||
    [];

  // prepare next save point
  interactionsSave = function (say, reply) {
    if (!localStorageAvailable) return;
    // limit number of saves
    if (interactionsHistory.length > recallInteractions)
      interactionsHistory.shift(); // removes the oldest (first) save to make space

    // do not memorize buttons; only user input gets memorized:
    if (
      // `bubble-button` class name signals that it's a button
      say.includes("bubble-button") &&
      // if it is not of a type of textual reply
      reply !== "reply reply-freeform" &&
      // if it is not of a type of textual reply or memorized user choice
      reply !== "reply reply-pick"
    )
      // ...it shan't be memorized
      return;

    // save to memory
    interactionsHistory.push({ say: say, reply: reply });
  };

  // commit save to localStorage
  interactionsSaveCommit = function () {
    if (!localStorageAvailable) return;
    localStorage.setItem(interactionsLS, JSON.stringify(interactionsHistory));
  };

  // set up the stage
  container.classList.add("bubble-container");
  var bubbleWrap = document.createElement("div");
  bubbleWrap.className = "bubble-wrap";
  container.appendChild(bubbleWrap);

  // install user input textfield
  this.typeInput = function (callbackFn) {
    var inputWrap = document.createElement("div");
    inputWrap.className = "input-wrap";
    var inputAffirm = document.createElement("div");
    inputAffirm.className = "input-affirm";
    inputAffirm.innerHTML = "AI生成内容可能有错误，仅供参考";
    var inputDefaultQuestion = document.createElement("div");
    inputDefaultQuestion.className = "input-default-question";
    inputDefaultQuestion.setAttribute("id", "chat-default-question");
    var inputSend = document.createElement("i");
    inputSend.setAttribute("id", "chat-send-btn");
    inputSend.className = "iconfont icon-fasong input-send";
    var inputText = document.createElement("textarea");
    inputText.setAttribute("placeholder", "Ask me anything...");
    inputWrap.appendChild(inputDefaultQuestion);
    inputWrap.appendChild(inputAffirm);
    inputWrap.appendChild(inputText);
    inputWrap.appendChild(inputSend);
    inputSend.addEventListener("click", function (e) {
      if (isLock) {
        return;
      }
      e.preventDefault();
      typeof bubbleQueue !== false ? clearTimeout(bubbleQueue) : false; // allow user to interrupt the bot
      var lastBubble = document.querySelectorAll(".bubble.say");
      lastBubble = lastBubble[lastBubble.length - 1];
      lastBubble.classList.contains("reply replayChart") &&
      !lastBubble.classList.contains("reply-freeform")
        ? lastBubble.classList.add("bubble-hidden")
        : false;
      addBubble(
        '<span class="bubble-button bubble-pick">' +
          inputText.value +
          "</span>",
        function () {},
        "reply reply-freeform"
      );
      // callback
      typeof callbackFn === "function"
        ? callbackFn({
            input: inputText.value,
            convo: _convo,
            standingAnswer: standingAnswer,
          })
        : false;
      inputText.value = "";
    });
    inputText.addEventListener("keypress", function (e) {
      // register user input
      if (e.keyCode == 13) {
        e.preventDefault();
        if (isLock) {
          return;
        }
        typeof bubbleQueue !== false ? clearTimeout(bubbleQueue) : false; // allow user to interrupt the bot
        var lastBubble = document.querySelectorAll(".bubble.say");
        lastBubble = lastBubble[lastBubble.length - 1];
        lastBubble.classList.contains("reply replayChart") &&
        !lastBubble.classList.contains("reply-freeform")
          ? lastBubble.classList.add("bubble-hidden")
          : false;
        addBubble(
          '<span class="bubble-button bubble-pick">' + this.value + "</span>",
          function () {},
          "reply reply-freeform"
        );
        // callback
        typeof callbackFn === "function"
          ? callbackFn({
              input: this.value,
              convo: _convo,
              standingAnswer: standingAnswer,
            })
          : false;
        this.value = "";
      }
    });
    container.appendChild(inputWrap);
    bubbleWrap.style.paddingBottom = "120px";
    inputText.focus();
  };
  inputCallbackFn ? this.typeInput(inputCallbackFn) : false;

  // init typing bubble
  var bubbleTyping = document.createElement("div");
  bubbleTyping.className = "bubble-typing imagine";
  for (dots = 0; dots < 3; dots++) {
    var dot = document.createElement("div");
    dot.className = "dot_" + dots + " dot";
    bubbleTyping.appendChild(dot);
  }
  bubbleWrap.appendChild(bubbleTyping);
  this.setDefaultQuestion = function (obj) {
    var addData = "";
    if (obj.data) {
      for (var i = 0; i < obj.data.length; i++) {
        addData +=
          '<div class="item" onClick="' +
          self +
          ".defaultQuestionSend('" +
          [i] +
          "')\">" +
          obj.data[i] +
          "</div>";
      }
      var questionBox = document.getElementById("chat-default-question");
      questionBox.innerHTML = addData;
      var h = questionBox.offsetHeight;
      bubbleWrap.style.paddingBottom = h + 120 + "px";
    }
  };
  this.activelySend = function (val) {
    if (isLock) {
      return;
    }
    typeof bubbleQueue !== false ? clearTimeout(bubbleQueue) : false; // allow user to interrupt the bot
    var lastBubble = document.querySelectorAll(".bubble.say");
    lastBubble = lastBubble[lastBubble.length - 1];
    lastBubble.classList.contains("reply replayChart") &&
    !lastBubble.classList.contains("reply-freeform")
      ? lastBubble.classList.add("bubble-hidden")
      : false;
    addBubble(
      '<span class="bubble-button bubble-pick">' + val + "</span>",
      function () {},
      "reply reply-freeform"
    );
    // callback
    typeof callbackFn === "function"
      ? callbackFn({
          input: val,
          convo: _convo,
          standingAnswer: standingAnswer,
        })
      : false;
  };
  // accept JSON & create bubbles
  this.talk = function (convo, here) {
    // all further .talk() calls will append the conversation with additional blocks defined in convo parameter
    _convo = Object.assign(_convo, convo); // POLYFILL REQUIRED FOR OLDER BROWSERS
    this.reply(_convo[here]);
    here ? (standingAnswer = here) : false;
  };
  this.lock = function (state) {
    isLock = state;
    var sendBtn = document.getElementById("chat-send-btn");
    if (state) {
      sendBtn.classList.add("hide");
    } else {
      sendBtn.classList.remove("hide");
    }
  };
  var iceBreaker = false; // this variable holds answer to whether this is the initative bot interaction or not
  this.reply = function (turn) {
    iceBreaker = typeof turn === "undefined";
    turn = !iceBreaker ? turn : _convo.robot;
    questionsHTML = "";
    addCopy = "";
    if (!turn) return;
    if (turn.reply !== undefined) {
      turn.reply.reverse();
      for (var i = 0; i < turn.reply.length; i++) {
        (function (el, count) {
          questionsHTML +=
            '<span class="bubble-button" style="animation-delay: ' +
            (animationTime / 2) * count +
            'ms" onClick="' +
            self +
            ".answer('" +
            el.answer +
            "', '" +
            el.question +
            "');this.classList.add('bubble-pick')\">" +
            el.question +
            "</span>";
        })(turn.reply[i], i);
      }
    }
    if (turn.copy) {
      var sayValue = turn.says[0].replace(/\s+/g,"&nbsp;").replace(/'/g, "＇").replace(/"([^"]*)"/g ,"“$1”")
      addCopy +=
        turn.says[0] +
        '<div class="actionBox"><div class="copyBtn" onClick="' +
        self +
        ".copyText('"+sayValue+"')\">" +
        "<i class='el-icon-document-copy'></i>点击复制</div><div>";
      //   console.log(addCopy);
      turn.says[0] = addCopy;
    }
    orderBubbles(turn.says, function () {
      bubbleTyping.classList.remove("imagine");
      questionsHTML !== ""
        ? addBubble(questionsHTML, function () {}, "reply")
        : bubbleTyping.classList.add("imagine");
      replyCallbackFn
        ? replyCallbackFn(turn.says, turn.copy ? turn.copy : false)
        : false;
    });
  };
  // navigate "answers"
  this.answer = function (key, content) {
    var func = function (key, content) {
      typeof window[key] === "function" ? window[key](content) : false;
    };
    _convo[key] !== undefined
      ? (this.reply(_convo[key]), (standingAnswer = key))
      : typeof responseCallbackFn === "function"
      ? responseCallbackFn(
          { input: key, convo: _convo, standingAnswer: standingAnswer },
          key
        )
      : func(key, content);

    // add re-generated user picks to the history stack
    if (_convo[key] !== undefined && content !== undefined) {
      interactionsSave(
        '<span class="bubble-button reply-pick">' + content + "</span>",
        "reply reply-pick"
      );
    }
  };

  // api for typing bubble
  this.think = function () {
    bubbleTyping.classList.remove("imagine");
    this.stop = function () {
      bubbleTyping.classList.add("imagine");
    };
  };
  this.copyText = function (val) {
    copyCallbackFn(val);
  };
  this.defaultQuestionSend = function (val) {
    defaultQuestionCallbackFn(val);
  };
  // "type" each message within the group
  var orderBubbles = function (q, callback) {
    var start = function () {
      setTimeout(function () {
        callback();
      }, animationTime);
    };
    var position = 0;
    for (
      var nextCallback = position + q.length - 1;
      nextCallback >= position;
      nextCallback--
    ) {
      (function (callback, index) {
        start = function () {
          addBubble(q[index], callback);
        };
      })(start, nextCallback);
    }
    start();
  };

  // create a bubble
  var bubbleQueue = false;
  var addBubble = function (say, posted, reply, live) {
    reply = typeof reply !== "undefined" ? reply : "";
    live = typeof live !== "undefined" ? live : true; // bubbles that are not "live" are not animated and displayed differently
    var animationTime = live ? this.animationTime : 0;
    var typeSpeed = live ? this.typeSpeed : 0;
    // create bubble element
    var bubble = document.createElement("div");
    var bubbleContent = document.createElement("span");
    bubble.className = "bubble imagine " + (!live ? " history " : "") + reply;
    bubbleContent.className = "bubble-content";
    bubbleContent.innerHTML = say;
    bubble.appendChild(bubbleContent);
    bubbleWrap.insertBefore(bubble, bubbleTyping);
    // answer picker styles
    if (reply !== "") {
      var bubbleButtons = bubbleContent.querySelectorAll(".bubble-button");
      for (var z = 0; z < bubbleButtons.length; z++) {
        (function (el) {
          if (!el.parentNode.parentNode.classList.contains("reply-freeform"))
            el.style.width = el.offsetWidth - sidePadding * 2 + widerBy + "px";
        })(bubbleButtons[z]);
      }
      bubble.addEventListener("click", function (e) {
        if (e.target.classList.contains("bubble-button")) {
          for (var i = 0; i < bubbleButtons.length; i++) {
            (function (el) {
              el.style.width = 0 + "px";
              el.classList.contains("bubble-pick")
                ? (el.style.width = "")
                : false;
              el.removeAttribute("onclick");
            })(bubbleButtons[i]);
          }
          this.classList.add("bubble-picked");
        }
      });
    }
    // time, size & animate
    wait = live ? animationTime * 2 : 0;
    minTypingWait = live ? animationTime * 6 : 0;
    if (say.length * typeSpeed > animationTime && reply == "") {
      wait += typeSpeed * say.length;
      wait < minTypingWait ? (wait = minTypingWait) : false;
      setTimeout(function () {
        bubbleTyping.classList.remove("imagine");
      }, animationTime);
    }
    live &&
      setTimeout(function () {
        bubbleTyping.classList.add("imagine");
      }, wait - animationTime * 2);
    bubbleQueue = setTimeout(function () {
      bubble.classList.remove("imagine");
      var offWidth = bubbleContent.offsetWidth
        ? bubbleContent.offsetWidth
        : fontSizeBy * say.length;
      var bubbleWidthCalc = offWidth + widerBy + "px";
      bubble.style.width = reply == "" ? bubbleWidthCalc : "";
      bubble.style.width = say.includes("<img src=")
        ? "50%"
        : bubble.style.width;
      bubble.classList.add("say");
      posted();

      // save the interaction
      interactionsSave(say, reply);
      !iceBreaker && interactionsSaveCommit(); // save point

      // animate scrolling
      containerHeight = container.offsetHeight;
      scrollDifference = bubbleWrap.scrollHeight - bubbleWrap.scrollTop;
      scrollHop = scrollDifference / 200;
      var scrollBubbles = function () {
        for (var i = 1; i <= scrollDifference / scrollHop; i++) {
          (function () {
            setTimeout(function () {
              bubbleWrap.scrollHeight - bubbleWrap.scrollTop > containerHeight
                ? (bubbleWrap.scrollTop = bubbleWrap.scrollTop + scrollHop)
                : false;
            }, i * 5);
          })();
        }
      };
      setTimeout(scrollBubbles, animationTime / 2);
    }, wait + animationTime * 2);
  };

  // recall previous interactions
  for (var i = 0; i < interactionsHistory.length; i++) {
    addBubble(
      interactionsHistory[i].say,
      function () {},
      interactionsHistory[i].reply,
      false
    );
  }
}

// below functions are specifically for WebPack-type project that work with import()

// this function automatically adds all HTML and CSS necessary for chat-bubble to function
function prepHTML(options) {
  // options
  var options = typeof options !== "undefined" ? options : {};
  var container = options.container || "chat"; // id of the container HTML element
  var parent_id = options.parent_id || "body"; // id of the container HTML element
  var relative_path = options.relative_path || "@/components/bubbles/";

  // make HTML container element
  window[container] = document.createElement("div");
  window[container].setAttribute("id", container);
  if (parent_id != "body") {
    document.getElementById(parent_id).appendChild(window[container]);
  } else {
    document.body.appendChild(window[container]);
  }

  // style everything
  var appendCSS = function (file) {
    var link = document.createElement("link");
    link.href = file;
    link.type = "text/css";
    link.rel = "stylesheet";
    link.media = "screen,print";
    document.getElementsByTagName("head")[0].appendChild(link);
  };
  if (options.relative_path) {
    appendCSS(relative_path + "style/input.css");
    appendCSS(relative_path + "style/reply.css");
    appendCSS(relative_path + "style/says.css");
    appendCSS(relative_path + "style/setup.css");
    appendCSS(relative_path + "style/typing.css");
  }
}

// exports for es6
if (typeof exports !== "undefined") {
  exports.Bubbles = Bubbles;
  exports.prepHTML = prepHTML;
}
