import {DateTime} from 'luxon'
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    if (typeof time === 'number') {
      // 毫秒格式的处理
      date = DateTime.fromMillis(time,{zone: 'Asia/Shanghai'})
    } else {
      // ISO格式的处理
      date = DateTime.fromISO(time, {zone: 'Asia/Shanghai'})
    }
  }
  const formatObj = {
    y: date.year,
    m: date.month,
    d: date.day,
    h: date.hour,
    i: date.minute,
    s: date.second,
    a: date.day,
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
