<template>
  <div @click="HandleClick">
    <el-link :href="hrefUrl"
             :underline="underline"
             ref="link"
             :disabled="isDownLoading"
             :icon="isDownLoading?'':'el-icon-download primaryColor_text'"
             :class="{'downLoading':isDownLoading}">{{text}}</el-link>
  </div>
</template>

<script>
import { getObjFromUrl } from '@/utils'
import request from '@/utils/request'

export default {
  props: {
    hrefUrl: {
      type: String,
      default: '',
    },
    underline: {
      type: Boolean,
      default: false,
    },
    isGet: {
      type: Boolean,
      default: false,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: this.$t('baseInfo.downLoad'),
      time: null,
    }
  },
  methods: {
    HandleClick() {
      this.text = this.$t('baseInfo.downLoading') //'下载准备中...'
      if (!this.isPost && !this.isGet) {
        this.$refs.link.$el.click()
      } else {
        const args = this.hrefUrl.split('?')
        const url = args[0]
        const paramsObj = getObjFromUrl(this.hrefUrl)
        let config = {
          url,
          method: this.isGet ? 'get' : 'post',
        }
        if (this.isGet) {
          config.params = paramsObj
        } else {
          config.data = paramsObj
        }
        request(config).then((res) => {
          if (res.status == 'ok') {
            const result = res.result
            const elink = document.createElement('a')
            elink.style.display = 'none'
            elink.href = result.furl
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
          }
          // let filename = 'default.xlsx'
          // if(res.request.getResponseHeader('Content-Disposition')){
          //   filename = res.request.getResponseHeader('Content-Disposition').split('filename=')[1];
          // }
          // if( res ){
          //   let blob = new Blob([res.data]);
          //   const elink = document.createElement('a');
          //   elink.download = filename;
          //   elink.style.display = 'none';
          //   elink.href = URL.createObjectURL(blob);
          //   document.body.appendChild(elink);
          //   elink.click();
          //   URL.revokeObjectURL(elink.href); //移除链接
          //   document.body.removeChild(elink); //移除a标签
          // }
        })
      }
      if (this.time) {
        this.time = null
      }
      this.time = setTimeout(() => {
        this.text = this.$t('baseInfo.downLoad')
      }, 15000)
    },
  },
  computed: {
    isDownLoading() {
      if (this.text == this.$t('baseInfo.downLoad')) {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {},
}
</script>

<style scoped lang="less">
/deep/.el-link.el-link--default {
  color: #ff7d00;
}
.downLoading {
  background-color: rgb(104, 101, 101) !important;
}
</style>
