import request from "@/utils/request";
import { formateObjToParamStr, formateFenxiParamStr } from "@/utils";
// import { encryptByDES } from '@/utils/crypto'
import qs from "qs";
import axios from "axios";
const BASE_API = process.env.VUE_APP_BASE_API;
const REAL_BASE_API =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_REAL_BASE_API
    : process.env.VUE_APP_BASE_API;
const LOGIN_API = process.env.VUE_APP_LOGIN_API;
const FENXI_HOST = "http://milk.cp.mktindex.com";
export function getWordTs(param) {
  return request({
    url: `${BASE_API}/api/get_word_ts`,
    method: "get",
    params: param,
  });
}
export function getParams(param) {
  return request({
    url: `${BASE_API}/api/sub/get_params`,
    method: "get",
    params: param,
  });
}
export function getUid(data) {
  return request({
    url: `${BASE_API}/api/sub/get_uid`,
    method: "post",
    data,
  });
}
export function getVolumeTable(param) {
  return request({
    url: `${BASE_API}/api/ecommerce`,
    method: "get",
    params: param,
  });
}

export function setMarked(data) {
  return request({
    url: `${BASE_API}/api/marked`,
    method: "post",
    data,
  });
}
export function getMarked(param) {
  return request({
    url: `${BASE_API}/api/sub/marked`,
    method: "get",
    params: param,
  });
}
export function getSearchMarked(param) {
  return request({
    url: `${BASE_API}/api/search_marked`,
    method: "get",
    params: param,
  });
}
export function getSearchData(param) {
  return request({
    url: `${BASE_API}/api/search`,
    method: "get",
    params: param,
  });
}
export function getShopListData(param) {
  return request({
    url: `${BASE_API}/api/sub/analyze`,
    method: "get",
    params: param,
  });
}

//获取属性分析、店铺分析、品牌分析 - 下载地址
export function getShopDataUrl(data) {
  return request({
    url: `${BASE_API}/api/sub/analyze`,
    method: "post",
    data: data,
  });
}
export function getFilingAttrAnalyze(param) {
  return request({
    url: `${BASE_API}/api/sub/filing_attr_analyze`,
    method: "get",
    params: param,
  });
}

export function getFilingAttrAnalyzeUrl(data) {
  return request({
    url: `${BASE_API}/api/sub/filing_attr_analyze`,
    method: "post",
    data: data,
  });
}
//我的订阅列表数据接口
export function getCustomCatData(param) {
  return request({
    url: `${BASE_API}/api/sub/custom_cat`,
    method: "get",
    params: param,
  });
}
export function getVolumeDataUrl(param) {
  return `${BASE_API}/api/ecommerce?` + formateObjToParamStr(param);
}
//获取top类目下载地址
export function getCategoryDataUrl(param) {
  return `${FENXI_HOST}/dwfcat2?` + formateFenxiParamStr(param);
}
//获取top品牌下载地址
export function getBrandDataUrl(param) {
  return `${FENXI_HOST}/dwtopbrands?` + formateFenxiParamStr(param);
}
//获取top商品下载地址
export function getCommodityDataUrl(param) {
  return `${FENXI_HOST}/dwtopitems?` + formateFenxiParamStr(param);
}
export function getCloudData(param) {
  return request({
    url: `${BASE_API}/api/cloud`,
    method: "get",
    params: param,
  });
}
export function getECloudData(data) {
  return request({
    url: `${BASE_API}/api/ecloud`,
    method: "post",
    data,
  });
}
export function getSocialData(param) {
  return request({
    url: `${BASE_API}/api/social`,
    method: "get",
    params: param,
  });
}
export function getPieChartData(param) {
  return request({
    url: `${BASE_API}/api/latest_social`,
    method: "get",
    params: param,
  });
}
export function getLineBarChartDataUrl(param) {
  return `${REAL_BASE_API}/api/search?` + formateObjToParamStr(param);
}
export function getPhone(param) {
  return request({
    url: `${BASE_API}/api/phone/setting`,
    method: "get",
    params: param,
  });
}
export function getMail(param) {
  return request({
    url: `${BASE_API}/api/get_mail_phone`,
    method: "get",
    params: param,
  });
}
export function setPhone(data) {
  return request({
    url: `${BASE_API}/api/phone/setting`,
    method: "PUT",
    data,
  });
}
export function addMail(data) {
  return request({
    url: `${BASE_API}/api/add_mail`,
    method: "POST",
    data,
  });
}
export function deletePhone(data) {
  return request({
    url: `${BASE_API}/api/phone/setting`,
    method: "DELETE",
    data,
  });
}
export function deleteMail(data) {
  return request({
    url: `${BASE_API}/api/del_mail`,
    method: "POST",
    data,
  });
}
export function putMarked(data) {
  return request({
    url: `${BASE_API}/api/sub/marked`,
    method: "PUT",
    data,
  });
}

export function deleteMarked(params) {
  return request({
    url: `${BASE_API}/api/sub/marked`,
    method: "DELETE",
    params,
  });
}

export function getWordLabel(data) {
  return request({
    url: `${BASE_API}/api/sub/getwordlabel`,
    method: "POST",
    data,
  });
}

export function getSaleReport(param) {
  return request({
    url: `${BASE_API}/api/sub/sale_report`,
    method: "get",
    params: param,
  });
}

export function getNoticeInfo(param) {
  return request({
    url: `${BASE_API}/api/sub/notice_info`,
    method: "get",
    params: param,
  });
}
export function exchangeRateCustom(data, method = "get") {
  return request({
    url: `${BASE_API}/api/site_custom_exchange_rate`,
    method: method,
    [method === "get" ? "params" : "data"]: data,
  });
}
export function exchangeRate(data, method = "get") {
  return request({
    url: `${BASE_API}/api/exchange_rate`,
    method: method,
    [method === "get" ? "params" : "data"]: data,
  });
}

// 品牌分析新增图表接口
export function getBrandTrend(params) {
  return request({
    url: `${BASE_API}/api/sub/brand_change_trend`,
    method: "get",
    params,
  });
}
export function postBrandTrend(params) {
  return request({
    url: `${BASE_API}/api/sub/brand_change_trend`,
    method: "post",
    data: params,
  });
}

// 用户对功能模块的访问日志统计
export function getModuleVisit(params) {
  return request({
    url: `${BASE_API}/api/sub/module_visit`,
    method: "get",
    params,
  });
}

// ****** 用户是否使用最新的类目结构 - 相关接口
export function getCatSetting(param) {
  return request({
    url: `${BASE_API}/api/cat/setting`,
    method: "get",
    params: param,
  });
}

export function putCatSetting(param) {
  return request({
    url: `${BASE_API}/api/cat/setting`,
    method: "put",
    params: param,
  });
}

export function getGenPPT(param) {
  return request({
    url: `${BASE_API}/api/sub/gen_ppt`,
    method: "get",
    params: param,
  });
}

export function clientError(data) {
	return request({
		url: `${BASE_API}/api/client_error`,
		method: 'post',
		data,
	})
}
