
<template>
  <div>
    <el-dialog :visible.sync="dialogVisible"
               :width="isPc? '450px' : '90%'"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               :show-close="showClose">
      <iframe id="login-ifm"
              style="
                    width: 100%;
                    margin: 0 auto;
                    height: 400px;
                    display: block;
                "
              scrolling="no"
              frameborder="0"
              :src="src"></iframe>
    </el-dialog>
  </div>
</template>

<script>
export default {
  //name: 'Login',
  props: {
    redirectUrl: {
      type: String,
      default: '',
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    isPc: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //   isPc_: this.isPc, //this.$store.state.isPc(),
      //   dialogVisible_: this.dialogVisible,
      //   showClose_: this.showClose,
      src: `https://loginnew.mktindex.com/cas/newlogin?redirect_url=http://social.moojing.com/#/search`,
    }
  },
  computed: {
    /*dialogVisible_: {
      get() {
        return this.dialogVisible
      },
      set(val) {
        this.dialogVisible = val
      },
    },*/
  },
  //   created() {},
}
</script>
