<template>
  <div class="date-picker-cpn">
    <div class="date-picker-select-area">
      <el-select size="mini"
                 v-if="showTsType"
                 v-model="timeType"
                 style="width: 130px; margin-right: 20px;"
                 @change="changeTsType">
        <el-option v-for="item in timeOptions"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                   :disabled="item.disabled"></el-option>
      </el-select>
    </div>
    <div class="date-picker-picker-area">
      <MonthPickers v-if="timeType == 'month'"
                    :dateTime="dateTime"
                    :styleObj="{height:30,lineHeight:30}"
                    @change="dateChange" />
      <WeekPickers v-if="timeType == 'week'"
                   :dateTime="dateTime"
                   :styleObj="{height:30,lineHeight:30}"
                   @change="dateChange" />
      <DayPickers v-if="timeType == 'day'"
                  :dateTime="dateTime"
                  :styleObj="{height:30,lineHeight:30}"
                  @change="dateChange" />
    </div>
  </div>
</template>


<script>
import MonthPickers from './monthPickers/MonthPickers.vue'
import DayPickers from './dayPickers/DayPickers.vue'
import WeekPickers from './weekPickers/WeekPickers.vue'
export default {
  name: 'DatePickerComp',
  components: {
    MonthPickers,
    WeekPickers,
    DayPickers,
  },
  data() {
    return {
      timeType: 'month',
      timeOptions: [
        {
          label: '月度时间段',
          value: 'month',
        },
        {
          label: '周度时间段',
          value: 'week',
        },
        {
          label: '日度时间段',
          value: 'day',
        },
      ],
    }
  },
  props: {
    dateTime: {
      type: Array,
      default: () => [],
    },
    showTsRange: {
      type: Boolean,
      default: false,
    },
    pickerOptionsStart: {
      type: Object,
      default: () => null,
    },
    pickerOptionsEnd: {
      type: Object,
      default: () => null,
    },
    defaultTsType: {
      type: String,
      default: '',
    },
  },
  provide() {
    return {
      pickerOptionsStart: this.pickerOptionsStart,
      pickerOptionsEnd: this.pickerOptionsEnd,
    }
  },
  computed: {
    showTsType() {
      return this.showTsRange
    },
  },
  methods: {
    changeTsType(val) {
      this.$emit('on-changeTsType', val)
    },
    dateChange(val) {
      this.$emit('on-changeDateTime', val)
    },
  },
  created() {
    if (this.defaultTsType) {
      this.timeType = this.defaultTsType
    }
  },
  watch: {
    timeType(newVal, oldVal) {
      this.$emit('on-changeTsType', newVal)
    },
    defaultTsType() {
      this.timeType = this.defaultTsType
    },
  },
}
</script>

<style lang="less" scoped>
.date-picker-cpn {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
