<template>
  <div style="width:100%;height:100%"
       v-loading="loading || errorItem.text=='RepeatRequest'"
       v-message="errorItem">
    <div class="legendBox"
         ref="legendBox"
         v-if="newOptions.isShowLegend"
         v-resize="onResize">
      <div class="item"
           :class="isHide(item.typ) ? 'active' : ''"
           v-for="item in legendTypeArr"
           :key="item.typ"
           @click="changeLegendTypeData(item)">
        <div class="colors"
             :style="{'background-color':item.color}"></div>{{item.name}}
      </div>
    </div>
    <div v-loading="loadingWordCloud"
         style="width:100%"
         :style="{ height: legendBoxHeight }">
      <div v-if="isOk"
           v-show="isShow"
           style="height:100%;width:100%">
        <WordCloudCom :data="WordsData"
                      id="mainsvg"
                      :nameKey="newOptions.nameLabel"
                      :valueKey="newOptions.valueLabel"
                      :margin="newOptions.margin"
                      :wordPadding='newOptions.wordPadding'
                      :rotate="newOptions.rotate"
                      :spiral="newOptions.spiral"
                      :fontScale="newOptions.fontScale"
                      :fontSize="newOptions.fontSize"
                      :font="newOptions.font"
                      :color="newOptions.colors"
                      :showTooltip="newOptions.showTooltip"
                      :wordClick="wordClickHandler">
        </WordCloudCom>
      </div>
    </div>
    <div class="noData"
         v-if="emptyType=='empty'&&WordsData&&WordsData.length==0">暂无数据</div>
  </div>
</template>

<script>
import WordCloudCom from './WordCloudCom.vue'
import { colorArr } from './colorOption'
import resize from 'vue-resize-directive'
const directives = {
  resize,
}
export default {
  name: 'WordCloudNew',
  directives,
  props: {
    /* WordsData: {
      type: Array,
      default: [],
    },*/
    options: {
      nameKey: 'word',
      nameLabel: '',
      valueKey: 'count',
      valueLabel: '',
      margin: { top: 0, right: 0, bottom: 0, left: 0 }, //图表外边距
      wordPadding: 1, //词间距
      rotate: { from: -60, to: 60, numOfOrientation: 5 }, //可设置角度的范围以及角度的个数
      spiral: 'archimedian', //词的布局方式 可选择'archimedian'或'rectangular'
      fontScale: 'sqrt', //词的大小缩放比例  可选择'sqrt'，'log'或'n'
      fontSize: [10, 80], //词的字号范围 [minSize, maxSize]
      font: 'impact', //词的字体名称，对应font-family的值  例如衬线字体'serif'，非衬线字体'Arial'
      colors: [], //配色集合，可配置两种类型的值：String (D3 scheme name)，或 Array（自定义的颜色列表）
      showTooltip: true, //显示 Tooltip  true/false; tooltip 的样式（如宽高、颜色等）可以通过 CSS 类 div.tooltip 配置
    },
    getDataMethod: {
      type: Function,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  components: {
    WordCloudCom,
  },
  data() {
    return {
      errorItem: { type: 'info', text: '' },
      loading: false,
      loadingWordCloud: false,
      WordsData: [],
      newOptions: { ...this.options },
      emptyType: 'empty',
      legendTypeArr: [],
      legendData: {},
      hideLegendArr: [], //隐藏的图列
      colorArr: colorArr,
      legendBoxHeight: '40px',
      isOk: false,
      isShow: true,
    }
  },
  methods: {
    onResize() {
      //   this.isOk = false
      //   this.$nextTick(() => {
      //     this.legendBoxHeight = `calc(100% - ${this.$refs.legendBox.offsetHeight}px)`
      //     this.isOk = true
      //   })
    },
    downloadWordcloudPng() {
      // 有2种方法都可以把svg下载成图片
      // 第一种 使用serializer将获取的svg转换为字符串，然后通过encodeURIComponent()转码
      var svg = document
        .getElementById('mainsvg')
        .getElementsByTagName('svg')[0]
      var serializer = new XMLSerializer()
      var toExport = svg.cloneNode(true)
      var bb = svg.getBBox()
      toExport.setAttribute(
        'viewBox',
        bb.x -
          20 +
          ' ' +
          (bb.y - 20) +
          ' ' +
          (bb.width + 40) +
          ' ' +
          (bb.height + 40)
      )
      toExport.setAttribute('width', bb.width + 200)
      toExport.setAttribute('height', bb.height + 200)
      var source =
        '<?xml version="1.0" standalone="no"?>\r\n' +
        serializer.serializeToString(toExport)
      var image = new Image()
      image.src =
        'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source)
      var canvas = document.createElement('canvas')
      canvas.width = bb.width + 200
      canvas.height = bb.height + 200
      var context = canvas.getContext('2d')
      context.fillStyle = '#fff'
      context.fillRect(0, 0, bb.width + 200, bb.height + 200)
      image.onload = function () {
        context.drawImage(image, 0, 0)
        var a = document.createElement('a')
        a.download = '词云图.png'
        a.href = canvas.toDataURL('image/png')
        a.click()
      }

      // 第二种转为base64
      //   var svg = document
      //     .getElementById('mainsvg')
      //     .getElementsByTagName('svg')[0]
      //   svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      //   svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      //   var toExport = svg.cloneNode(true)
      //   var bb = svg.getBBox()
      //   toExport.setAttribute(
      //     'viewBox',
      //     bb.x -
      //       20 +
      //       ' ' +
      //       (bb.y - 20) +
      //       ' ' +
      //       (bb.width + 40) +
      //       ' ' +
      //       (bb.height + 40)
      //   )
      //   toExport.setAttribute('width', bb.width)
      //   toExport.setAttribute('height', bb.height)
      //   var imgsrc =
      //     'data:image/svg+xml;base64,' +
      //     window.btoa(unescape(encodeURIComponent(toExport.outerHTML)))
      //   var canvas = document.createElement('canvas')
      //   canvas.width = bb.width
      //   canvas.height = bb.height
      //   var context = canvas.getContext('2d')
      //   context.fillStyle = '#fff'
      //   context.fillRect(0, 0, bb.width, bb.height)
      //   var image = new Image()
      //   image.src = imgsrc
      //   image.onload = function () {
      //     context.drawImage(image, 0, 0)
      //     var a = document.createElement('a')
      //     a.download = '词云图.png'
      //     a.href = canvas.toDataURL('image/png')
      //     a.click()
      //   }
    },
    setToolTip(newVal) {
      setTimeout(() => {
        let overLayer = document.getElementsByClassName('tooltip')
        if (overLayer && overLayer.length) {
          for (var i = 0; i < overLayer.length; i++) {
            let item = overLayer[i]
            if (newVal) {
              document.body.removeChild(item)
            } else {
              item.style.opacity == newVal ? 0 : 0.7
            }
          }
        }
      }, 300)
    },
    changeColor(id) {
      let obj = _.find(this.colorArr, function (o) {
        return o.id == id
      })
      if (obj) {
        return obj.color
      } else {
        return 'rgb(112, 103, 216)'
      }
    },
    isHide(typ) {
      if (this.hideLegendArr.includes(typ)) {
        return true
      } else {
        return false
      }
    },
    changeLegendTypeData(item) {
      if (this.legendTypeArr.length == 1) {
        if (this.hideLegendArr.length == 0) {
          this.hideLegendArr = [item.typ]
        } else {
          this.hideLegendArr = []
        }
        this.isShow = !this.isShow
        return
      }
      this.loadingWordCloud = true
      //   console.log(this.loadingWordCloud)
      let typ = item.typ
      let arr = _.cloneDeep(this.hideLegendArr)
      if (arr.includes(typ)) {
        arr.splice(arr.indexOf(typ), 1)
      } else {
        arr.push(typ)
      }
      this.hideLegendArr = arr
      const filteredData = _.omit(this.legendData, arr)
      this.WordsData = _.flatMapDeep(filteredData)
      setTimeout(() => {
        this.loadingWordCloud = false
      }, 300)
    },
    getWordsData() {
      var elements = document.querySelectorAll('body .tooltip')
      for (var i = 0; i < elements.length; i++) {
        elements[i].parentNode.removeChild(elements[i])
      }
      this.loading = true
      this.loadingWordCloud = true
      this.isOk = false
      this.isShow = true
      this.$set(this.errorItem, 'type', 'info')
      this.$set(this.errorItem, 'text', '')
      this.setToolTip(this.loading)
      let param = this.params
      this.getDataMethod(param)
        .then((res) => {
          this.emptyType = 'empty'
          if (res.status == 'ok') {
            if (res.result) {
              //  let newresult = res.result.slice(0, 30)
              let WordsData = res.result.map((item) => {
                if (this.newOptions.nameKey != this.newOptions.nameLabel) {
                  item[this.newOptions.nameLabel] =
                    item[this.newOptions.nameKey]
                }
                if (this.newOptions.valueKey != this.newOptions.valueLabel) {
                  item[this.newOptions.valueLabel] =
                    item[this.newOptions.valueKey]
                }
                if (item.typ) {
                  item.color = this.changeColor(item.typ)
                }
                return item
              })
              if (this.newOptions.isShowLegend) {
                this.legendData = _.groupBy(WordsData, 'typ')
                this.legendTypeArr = _.map(this.legendData, function (item) {
                  return {
                    typ: item[0].typ,
                    name: item[0].ner_name,
                    color: item[0].color,
                  }
                })
                this.$nextTick(() => {
                  this.legendBoxHeight = `calc(100% - ${this.$refs.legendBox.offsetHeight}px)`
                  this.isOk = true
                })
                if (this.params.words) {
                  let arr = this.params.words.split(',')
                  let obj = _.find(this.legendTypeArr, function (o) {
                    return o.typ == arr[0]
                  })
                  if (obj) {
                    if (
                      this.newOptions.legendSelectedArr &&
                      this.newOptions.legendSelectedArr.length == 0
                    ) {
                      this.newOptions.legendSelectedArr = arr
                    } else {
                      let legendSelectedArr = _.cloneDeep(
                        this.newOptions.legendSelectedArr
                      )
                      this.newOptions.legendSelectedArr = _.union(
                        arr,
                        legendSelectedArr
                      )
                    }
                  }
                }
              }
              if (
                this.newOptions.legendSelectedArr &&
                this.newOptions.legendSelectedArr.length > 0
              ) {
                let arr1 = this.legendTypeArr
                let arr2 = this.newOptions.legendSelectedArr
                let arr3 = _.filter(
                  arr1,
                  (item) => !arr2.includes(item.typ)
                ).map((item) => item.typ)
                this.hideLegendArr = arr3
                const filteredData = _.omit(this.legendData, arr3)
                WordsData = _.flatMapDeep(filteredData)
              }
              this.WordsData = WordsData
              this.$emit('passTableData', res.result)
            } else {
              this.WordsData = []
              this.$emit('passTableData', [])
            }
            this.$set(this.errorItem, 'type', 'info')
            this.$set(this.errorItem, 'text', '')
          } else {
            this.$set(this.errorItem, 'type', 'warning')
            this.$set(this.errorItem, 'text', res.backMsg)
            this.$emit('passTableData', [])
          }
          this.loading = false
          this.setToolTip(this.loading)
          this.loadingWordCloud = false
        })
        .catch((e) => {
          this.loading = false
          this.loadingWordCloud = false
          this.WordsData = []
          this.emptyType = 'noEmpty'
          this.$emit('passTableData', [])
          if (e.data && e.data.error_code && e.data.error_code == '-416') {
            this.$set(this.errorItem, 'type', 'notice')
            this.$set(this.errorItem, 'text', e.backMsg)
            return
          }
          this.$set(this.errorItem, 'type', 'error')
          this.$set(this.errorItem, 'text', e.backMsg)
        })
        .finally(() => {
          this.loading = false
          this.loadingWordCloud = false
        })
    },
    wordClickHandler(name, value, vm, d) {
      this.$emit('wordClickHandler', {
        name: name,
        value: value,
        vm: vm,
        item: d,
      })
    },
  },
  computed: {
    // legendBoxHeight: function () {
    //   return `calc(100% - ${this.legendHeight}px)`
    // },
  },
  watch: {
    params: {
      handler(newVal, oldVal) {
        this.setOneGet()
      },
      deep: true,
    },
  },
  created() {
    this.setOneGet = _.debounce(this.getWordsData, 500)
  },
  mounted() {
    this.getWordsData()
  },
}
</script>

<style scoped lang="less">
.legendBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 5% 0;
  box-sizing: border-box;
  .item {
    margin: 0 12px 10px 0;
    cursor: pointer;
    font-size: 13px;
    .colors {
      display: inline-block;
      width: 25px;
      height: 14px;
      border-radius: 4px;
      vertical-align: top;
      margin-right: 2px;
    }
  }
  .active {
    color: #cccccc;
    .colors {
      background: #cccccc !important;
    }
  }
}
.noData {
  width: 100%;
  text-align: center;
  position: relative;
  top: -60%;
  left: 0;
}
.emptyData {
  min-height: 60px;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -102%;
  left: 0;
  .empty_text {
    color: #67c23a;
    background: #e1f3d8;
    height: 48px;
    line-height: 48px;
    width: 50%;
  }
}
.wordCloud {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: unset !important;
  height: 100% !important;
  /deep/ .chart {
    g {
      text:hover {
        cursor: pointer;
      }
    }
  }
  //   /deep/.resize-sensor {
  //     .resize-sensor-shrink {
  //       div {
  //         width: 100% !important;
  //         height: 100% !important;
  //       }
  //     }
  //   }
}
</style>