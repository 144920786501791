export function getLastFullWeekStartAndEnd(month, year) {
  //根据给定的月份和年份获取所需的最后一个完整周的开始和结束时间
  let lastDayOfMonth = new Date(year, month, 0).getDate()
  let lastDayOfWeek = new Date(year, month - 1, lastDayOfMonth).getDay() // 获取当月最后一天是星期几

  let endDay = lastDayOfMonth - lastDayOfWeek // 获取完整周的结束日期

  let endWeekStart = new Date(year, month - 1, endDay - 6) // 完整周开始时间
  let endWeekEnd = new Date(year, month - 1, endDay) // 完整周结束时间

  return {
    start: formatDate(endWeekStart),
    end: formatDate(endWeekEnd),
  }
}

export function getStartAndEndOfWeek(date) {
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) // 考虑周日的情况

  const startDate = new Date(date)
  startDate.setDate(diff)

  const endDate = new Date(date)
  endDate.setDate(diff + 6)

  // 格式化日期
  const formattedStartDate = startDate.toISOString().split('T')[0]
  const formattedEndDate = endDate.toISOString().split('T')[0]

  return {
    startOfWeek: formattedStartDate,
    endOfWeek: formattedEndDate,
  }
}

export function formatDate(date, cFormat) {
  const format = cFormat || '{y}-{m}-{d}'
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const formatObj = {
    y: year,
    m: month,
    d: day,
  }
  const time_str = format.replace(/{([ymd])+}/g, (result, key) => {
    const value = formatObj[key]
    return value.toString()
  })
  return time_str
}

/**
 * 判断传入的date是month格式还是day格式
 * @param {string} dateStr '****-**-**' or '****-**-**'
 * @returns {string} 'month' 'day' ''
 */
export function getTsType(dateStr) {
  const parts = dateStr.split('-');
    if (parts.length === 2) {
        return 'month';
    } else if (parts.length === 3) {
        return 'day';
    } else {
        return '';
    }
}

export const tsTypeMap = {
  month: '{y}-{m}',
  week: '{y}-{m}-{d}',
  day: '{y}-{m}-{d}'
}
