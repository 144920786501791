// 普通饼图的option配置
export const pieOption = {
  textStyle: {
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
  },
  title: {
    text: 'Traffic Sources',
    left: 'center',
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}*10 ‰)',
    // formatter: function (data) {
    //   return (
    //     data.name + '<br/>' + data.seriesName + '：' + data.value * 100 + '%'
    //   )
    // },
  },
  /* legend: {
    orient: 'vertical',
    left: 'left',
    data: [], //['Direct', 'Email', 'Ad Networks', 'Video Ads', 'Search Engines'],
  },*/
  series: [
    {
      name: 'Traffic Sources',
      type: 'pie',
      radius: '55%',
      center: ['50%', '60%'],
      data: [] /*[
        { value: 335, name: 'Direct' },
        { value: 310, name: 'Email' },
        { value: 234, name: 'Ad Networks' },
        { value: 135, name: 'Video Ads' },
        { value: 1548, name: 'Search Engines' },
      ],*/,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
}

// 柱状图option配置
export const ddBarOption = {
  color: ['#FF7D00', '#BFBFBF', '#BFBFBF', '#fed141'],
  title: {
    show: false,
  },
  tooltip: {
    // trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
    },
    appendToBody: true,
    confine: true,
    formatter: function (data) {
      if (data.seriesName == '负向率') {
        return data.seriesName + ':' + threeDigitsPercentage(data.value)
      }
      return data.seriesName + ':' + data.value
    },
  },
  legend: { show: false },
  xAxis: {
    type: 'value',
    show: false,
  },
  yAxis: {
    type: 'category',
    show: false,
    data: [],
  },
  grid: {
    left: '0',
    right: '0',
    bottom: '0',
    top: '0',
    show: false,
    containLabel: false,
  },
  series: [],
}

// 点图option配置
export const scatterOption = {
  textStyle: {
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
  },
  title: {
    text: 'Traffic Sources',
    left: 'center',
  },
  tooltip: {
    trigger: 'item',
    // formatter: '{a} <br/>{b} : {c} ({d}*10 ‰)',
    formatter: function (data) {
      return (
        data.name + '<br/>' + data.seriesName + '：' + data.value * 100 + '%'
      )
    },
  },
  /* legend: {
    orient: 'vertical',
    left: 'left',
    data: [], //['Direct', 'Email', 'Ad Networks', 'Video Ads', 'Search Engines'],
  },*/
  series: [
    {
      name: 'Traffic Sources',
      type: 'scatter',
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
}

// 基础y轴的option配置
export const mixyAxisItem = {
  type: 'value',
  nameTextStyle: {
    color: '#999999',
  },
  name: '',
  position: '',
  offset: 0,
  axisLine: {
    lineStyle: {
      color: '#088DFF',
    },
  },
  axisLabel: {
    formatter: function (value) {
      if (value >= 10000) {
        if (value / 10000 >= 10000) {
          return value / 10000 / 10000 + '亿'
        }
        return value / 10000 + '万'
      }
      return value
    },
    show: true,
    color: '#999999',
  },
  axisTick: {
    show: true,
  },
  /*splitLine: {
    lineStyle: {
      color: '#eeeeee',
    },
  },*/
}

// 基本 线&bar图 option配置
export const lineBarOptionTemp = {
  /* color: ['#3D9EFF', '#FFC44E'], //'#088DFF','#F46A4E'*/
  tooltip: {
    trigger: 'axis', //axis
    axisPointer: {
      type: 'cross',
    },
    formatter: '{b}<br/>{a0}: {c0}<br />{a1}: {c1}<br/>{a2}:{c2}%',
  },
  grid: {
    left: '10%',
    right: '10%',
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true },
    },
  },
  legend: {
    //data: ['正向声量', '负向声量', '负向声量占比'],
    data: [],
    orient: 'horizontal',
    left: 'center',
    top: 0,
    itemHeight: 12,
    itemGap: 30,
    textStyle: {
      padding: [3, 0, 0, 0],
    },
  },
  xAxis: [
    {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#999999',
        },
      },
      //y刻度
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        lineStyle: {
          color: '#999999',
        },
      },
      axisTick: {
        alignWithLabel: true,
      },
      data: [],
    },
  ],
  yAxis: [],
  series: [],
}

// 声量 图表 option配置
export const mixOptionTemp = {
  /* color: ['#3D9EFF', '#FFC44E'], //'#088DFF','#F46A4E'*/
  tooltip: {
    trigger: 'axis', //axis
    axisPointer: {
      type: 'cross',
    },
    formatter: '{b}<br/>{a0}: {c0}<br />{a1}: {c1}<br/>{a2}:{c2}%',
  },
  grid: {
    left: '10%',
    right: '10%',
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true },
    },
  },
  legend: {
    //data: ['正向声量', '负向声量', '负向声量占比'],
    data: [],
    orient: 'horizontal',
    left: 'center',
    top: 0,
    itemHeight: 12,
    itemGap: 30,
    textStyle: {
      padding: [3, 0, 0, 0],
    },
  },
  xAxis: [
    {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#999999',
        },
      },
      //y刻度
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        lineStyle: {
          color: '#999999',
        },
      },
      axisTick: {
        alignWithLabel: true,
      },
      data: [],
    },
  ],
  yAxis: [],
  series: [],
}

// 柱状图 数据 配置
export const seriesItem_dd = {
  name: '',
  type: 'bar',
  stack: 'total',
  label: {
    show: true,
  },
  emphasis: {
    focus: 'series',
  },
  data: [],
}

// 柱状图配置
export let ddOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
    },
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLine: {
      lineStyle: {
        color: '#999999',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#999999',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
    },
  },
  series: [],
}
