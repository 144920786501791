
<template>
  <div style="width:100%;height:100%"
       v-loading="loading">
    <el-button-group class="btns">
      <el-button @click="()=>{isShowEcharts = true}"
                 :style="{ background: isShowEcharts?'goldenrod':'white',color:isShowEcharts?'white':'black' }">图表</el-button>
      <el-button @click="()=>{isShowEcharts = false}"
                 :style="{ background: isShowEcharts?'white':'goldenrod',color:isShowEcharts?'black':'white'  }">列表</el-button>
    </el-button-group>
    <div style="width:100%;height: calc(100% - 43px);">
      <Echarts v-if="isShowEcharts"
               :type="type"
               :options="options_echarts"
               :mixConfig="mixConfig"
               :getDataMethod="getDataMethod"
               :params="params"
               @passTableData="passTableData"></Echarts>
      <VueDatatable v-else
                    :options="options_datatable"
                    :getDataMethod="getDataMethod"
                    :passData="passData"></VueDatatable>
    </div>
  </div>
</template>

<script>
import VueDatatable from './VueDatatable.vue'
import Echarts from './Echarts.vue'
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
export default {
  components: {
    VueDatatable,
    Echarts,
  },
  props: {
    type: {
      type: String,
      default: 'pie',
    },
    options_echarts: {
      type: Object,
      default: () => {
        return {}
      },
    },
    options_datatable: {
      type: Object,
      default: () => {
        return {
          supportBackup: true,
          supportNested: true,
          tblClass: 'table-bordered',
          tblStyle: 'color: #666',
          Pagination: true,
          pageSizeOptions: [5, 10, 15, 20],
          columns: [],
          params: {},
        }
      },
    },
    getDataMethod: {
      type: Function,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {
        return {}
      },
    },
    mixConfig: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      loading: false,
      isShowEcharts: true,
      passData: {},
    }
  },
  methods: {
    passTableData(data) {
      this.loading = false
      let resultArray = []
      let timeKeys = data[this.options_datatable.columns[1].field].map(
        (item) => {
          return item[0]
        }
      )

      timeKeys.map((timeItem) => {
        let item = {}
        this.options_datatable.columns.map((columnItem) => {
          if (columnItem.field === 'time') {
            if (columnItem.isTimeStamp) {
              item.time = parseTime(timeItem, '{y}-{m}-{d}')
            } else {
              item.time = timeItem
            }
          } else {
            if (columnItem.isPercent) {
              item[columnItem.field] =
                Number(
                  data[columnItem.field].find((dataItem) => {
                    return dataItem[0] == timeItem
                  })[1] * 100
                ).toFixed(1) + '%'
            } else {
              item[columnItem.field] = data[columnItem.field].find(
                (dataItem) => {
                  return dataItem[0] == timeItem
                }
              )[1]
            }
          }
        })
        resultArray.push(item)
      })
      this.passData = { total: timeKeys.length, data: resultArray }
    },
  },
}
</script>

<style lang="less" scoped>
.btns {
  //float: right;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  /deep/ .el-button {
    width: 50px;
    height: 33px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:hover,
    :focus {
      background: goldenrod !important;
      color: white;
    }
  }
  .el-button:not(:last-child) {
    border-right: none;
  }
  .el-button:last-child {
    border-left: none;
  }
}
</style>
