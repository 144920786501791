<template>
  <div class="month-picker-panel"
       id="monthPicker">
    <div class="main-area">
      <div class="start-month">
        <el-date-picker v-model="startMonth"
                        style="width:322px;position:absolute;top:0;left:0"
                        align="center"
                        ref="startDate"
                        type="month"
                        key="startDate"
                        :append-to-body="false"
                        :picker-options="pickerOptionsStart"
                        @change="startChange"
                        @blur="startblur"
                        value-format="yyyy-MM"
                        placeholder="选择月">
        </el-date-picker>
      </div>
      <div class="end-month">
        <el-date-picker v-model="endMonth"
                        style="width:322px;position:absolute;top:0;left:322px"
                        align="center"
                        ref="endDate"
                        type="month"
                        key="endDate"
                        :append-to-body="false"
                        :picker-options="pickerOptionsStart"
                        @change="endChange"
                        @blur="endblur"
                        value-format="yyyy-MM"
                        placeholder="选择月">
        </el-date-picker>
      </div>
    </div>
    <div class="btn-area">
      <el-button type="primary"
                 size="mini"
                 @click="changeTime">确认</el-button>
      <el-button size="mini"
                 @click="cancleChange">取消</el-button>
    </div>
  </div>
</template>


<script>
// import { timeRnage } from '@/mixins/mixins.js'
import { DateTime } from 'luxon'

const ZONE = 'Asia/Shanghai'
export default {
  // mixins: [timeRnage],
  inject: ['pickerOptionsStart'],
  name: 'PickerPanel',
  components: {},
  props: {
    dateTime: {
      type: Array,
      default: () => [],
    },
    defaultMinDate: {
      type: String,
      default: null,
    },
    defaultMaxDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      startYear: new Date().getFullYear(),
      startMonth: '',
      endYear: new Date().getFullYear(),
      endMonth: '',
      startTime: '',
      endTime: '',
      minDate: new Date(),
      maxDate: new Date(),
    }
  },
  watch: {
    dateTime: {
      handler(newVal, oldVal) {
        this.startMonth = newVal[0]
        this.endMonth = newVal[1]
        // this.startMonth = Number(newVal[0].split('-')[1])
        // this.endMonth = Number(newVal[1].split('-')[1])
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    startChange(val) {
      this.$refs.startDate.pickerVisible = true
      this.$refs.endDate.pickerVisible = true
    },
    startblur(val) {
      this.$refs.startDate.pickerVisible = true
      this.$refs.endDate.pickerVisible = true
    },
    endChange(val) {
      this.$refs.startDate.pickerVisible = true
      this.$refs.endDate.pickerVisible = true
    },
    endblur(val) {
      this.$refs.startDate.pickerVisible = true
      this.$refs.endDate.pickerVisible = true
    },
    changeTime() {
      let startTs = DateTime.fromISO(this.startMonth).setZone(ZONE).ts
      let endTs = DateTime.fromISO(this.endMonth).setZone(ZONE).ts
      if (endTs < startTs) {
        this.$message.error('结束时间不能小于开始时间!')
        return
      }
      this.$emit('on-changeTime', [this.startMonth, this.endMonth])
    },
    cancleChange() {
      this.$emit('on-cancleChange')
    },
  },
  created() {
    if (this.dateTime.length > 0) {
      this.startMonth = this.dateTime[0]
      this.endMonth = this.dateTime[1]
    }
  },
  mounted() {
    this.$refs.startDate.pickerVisible = true
    this.$refs.endDate.pickerVisible = true
  },
}
</script>

<style lang="less" >
#monthPicker {
  width: 644px;
  .main-area {
    display: flex;
    height: 260px;
    .el-month-table td .cell {
      border-radius: 4px;
      text-decoration: none;
    }
    .el-month-table td.current:not(.disabled) .cell {
      color: #fff;
      background: #3a63f3;
    }
    .el-picker-panel {
      margin-top: 0;
      margin-bottom: 0;
      border-left: 0;
    }
    .popper__arrow {
      display: none;
    }
    .el-input__prefix {
      display: none;
    }
    .el-input__inner {
      display: none;
    }
    .start-month,
    .end-month {
      position: relative;
    }
    .month-picker__container {
      width: 300px;
    }
    .month-picker__month {
      margin: 5px 10px;
      border: 1px solid gray;
      border-radius: 4px;
    }
    .month-picker__month.selected {
      //   background-color: #3a63f3;
      //   box-shadow: inset 0 0 3px #3a63f3, 0px 2px 5px rgb(85 176 242 / 20%)
      background-color: #3a63f3;
      box-shadow: inset 0 0 3px #3a63f3, 0px 2px 5px rgb(85 176 242 / 20%);
    }
    .month-picker__year button {
      height: 30px;
      line-height: 30px;
    }
  }
  .btn-area {
    height: 30px;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;

    .el-button {
      margin-right: 10px;
    }
  }
}
.month-picker-panel .main-area .month-picker__year button {
  line-height: 26px;
}
</style>
