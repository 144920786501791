<template>
  <div>
    <el-dropdown @command="handleCommand"
                 :disabled="isDownLoading"
                 placement="bottom">
      <span class="el-dropdown-link">
        {{text}}
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="table">下载为表格</el-dropdown-item>
        <el-dropdown-item command="img">下载为图片</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div style="display:none;">
      <el-link :href="hrefUrl"
               :underline="underline"
               ref="link"
               :disabled="isDownLoading"
               :icon="isDownLoading?'':'el-icon-download primaryColor_text'"
               :class="{'downLoading':isDownLoading}"></el-link>
    </div>
  </div>
</template>

<script>
import { getObjFromUrl } from '@/utils'
import request from '@/utils/request'

export default {
  props: {
    hrefUrl: {
      type: String,
      default: '',
    },
    underline: {
      type: Boolean,
      default: false,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: this.$t('baseInfo.downLoad'),
      time: null,
    }
  },
  methods: {
    handleCommand(command) {
      if (command == 'table') {
        this.HandleClick()
      } else if (command == 'img') {
        this.$emit('downImg')
      }
    },
    HandleClick() {
      this.text = this.$t('baseInfo.downLoading') //'下载准备中...'
      if (!this.isPost) {
        this.$refs.link.$el.click()
      } else {
        const args = this.hrefUrl.split('?')
        const url = args[0]
        const paramsObj = getObjFromUrl(this.hrefUrl)
        let config = {
          url,
          method: 'post',
          data: paramsObj,
        }
        request(config).then((res) => {
          if (res.status == 'ok') {
            const result = res.result
            const elink = document.createElement('a')
            elink.style.display = 'none'
            elink.href = result.furl
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
          }
        })
      }
      if (this.time) {
        this.time = null
      }
      this.time = setTimeout(() => {
        this.text = this.$t('baseInfo.downLoad')
      }, 10000)
    },
  },
  computed: {
    isDownLoading() {
      if (this.text == this.$t('baseInfo.downLoad')) {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {},
}
</script>

<style scoped lang="less">
/deep/ .el-dropdown-link {
  color: #ff7d00;
  font-size: 14px;
}
/deep/.el-link.el-link--default {
  color: #ff7d00;
}
.downLoading {
  color: rgb(104, 101, 101) !important;
}
</style>
