import {DateTime} from 'luxon'
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    if (typeof time === 'number') {
      // 毫秒格式的处理
      date = DateTime.fromMillis(time,{zone: 'Asia/Shanghai'})
    } else {
      // ISO格式的处理
      date = DateTime.fromISO(time, {zone: 'Asia/Shanghai'})
    }
  }
  const formatObj = {
    y: date.year,
    m: date.month,
    d: date.day,
    h: date.hour,
    i: date.minute,
    s: date.second,
    a: date.day,
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

export function createDateTime(time){
  const format = getTimeFormat(time)
  // 1: ISO样式 2:毫秒样式 3:JS Date样式
  switch (format) {
    case 1:
      return DateTime.fromISO(time, {zone: 'Asia/Shanghai'})
    case 2:
      return DateTime.fromMillis(time,{zone: 'Asia/Shanghai'})
    case 3:
      return DateTime.fromJSDate(time,{zone: 'Asia/Shanghai'})
    case -1:
      throw new Error('time format error')
  }
}

/**
 *
 * @param {*} time String | Object | Number
 * 接受三个格式 ISO格式,Date格式,毫秒数格式
 */
export function getTimeTs(time) {
  try{
    const dateTime = createDateTime(time)
    return dateTime.ts
  }catch{
    console.log('time format error')
  }
}

/**
 * get time params format
 * @param {*} time String | Object | Number
 */
function getTimeFormat(time) {
   // 判断ISO格式
  if(typeof time == 'string'){
    return 1
  }
  // 判断毫秒格式
  if (typeof time == 'number') {
    return 2;
  }
  // JS Date格式
  if (typeof time == 'object') {
    return 3;
  }
  return -1;
}

