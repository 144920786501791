<template>
  <div class="picks">
    <el-popover v-model="visible"
                popper-class="picksPopover">
      <div class="month-picker-cpn"
           slot="reference"
           :style="{height:styleObj.height+'px',lineHeight:styleObj.lineHeight+'px'}">
        <span class="el-icon-date"
              :style="{lineHeight:styleObj.lineHeight-8+'px'}"></span>
        <PickerInput :dateTime="startDate" />
        <span class="el-range-separator"
              :style="{lineHeight:styleObj.lineHeight-8+'px'}">至</span>
        <PickerInput :dateTime="endDate" />
      </div>
      <div v-if="visible">
        <PickerPanel :dateTime="[startDate,endDate]"
                     @on-changeTime="changeTime"
                     @on-cancleChange="cancleChange" />
      </div>
    </el-popover>
  </div>

</template>


<script>
import PickerInput from '../common/PickerInput.vue'
import PickerPanel from './PickerPanel.vue'
import { LuxonTime } from 'common'
export default {
  name: 'MonthPickers',
  model: {
    prop: 'dateTime',
    event: 'change',
  },
  props: {
    styleObj: {
      type: Object,
      default: function () {
        return {
          height: 30,
          lineHeight: 30,
        }
      },
    },
    dateTime: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PickerInput,
    PickerPanel,
  },
  data() {
    return {
      visible: false,
      startDate: '',
      endDate: '',
    }
  },
  methods: {
    showPickerPanel() {
      this.visible = !this.visible
    },
    changeTime(dateTime) {
      this.startDate = LuxonTime.parseTime(dateTime[0], '{y}-{m}-{d}')
      this.endDate = LuxonTime.parseTime(dateTime[1], '{y}-{m}-{d}')
      this.$emit('change', dateTime)
      this.visible = false
    },
    cancleChange() {
      this.visible = false
    },
  },
  created() {
    this.startDate = LuxonTime.parseTime(this.dateTime[0], '{y}-{m}-{d}')
    this.endDate = LuxonTime.parseTime(this.dateTime[1], '{y}-{m}-{d}')
  },
  watch: {
    dateTime: {
      handler(newVal, oldVal) {
        this.startDate = this.dateTime[0]
        this.endDate = this.dateTime[1]
      },
    },
  },
}
</script>

<style lang="less" scoped>
.month-picker-cpn {
  display: flex;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dcdfe6;
  background: #fff;
  border-radius: 4px;
  padding: 3px 10px;

  .el-range-separator {
    line-height: 22px;
  }

  .iconfont-calendar,
  .el-icon-date {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 20px;
  }
  .iconfont-calendar::before {
    font-family: 'iconfont';
    content: '\e600';
  }
}
</style>
