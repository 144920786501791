<template>
  <div>
    <div class="setUp"
         v-loading="loading">
      <div class="title"
           v-if="!showMail">手机号设置</div>
      <div class="title"
           v-if="showMail">邮箱设置</div>
      <!-- <el-radio-group v-model="radio"
                      v-if="showMail"
                      style="margin-bottom: 10px"
                      size="small">
        <el-radio-button label="phone">手机</el-radio-button>
        <el-radio-button label="mail">邮箱</el-radio-button>
      </el-radio-group> -->
      <template v-if="radio == 'phone'">
        <div class="text">您的主手机号 ：<span class="phone">{{mainPhone}}</span></div>
        <div class="text">您的辅手机号 ：
          <div class="assistPhoneList-area">
            <div v-for="(item) in assistPhoneList"
                 :key="item.phone_id"
                 class="assistPhoneList">
              <span class="phone">{{item.phone}}</span>
              <el-button class="sub"
                         type="danger"
                         size="mini"
                         @click="delPhone(item.phone_id)">删除</el-button>
            </div>
          </div>
        </div>
        <div class="inputBox">
          <div class="phoneNumBox">
            <el-input v-model="phoneNum"
                      onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                      placeholder="请输入内容"></el-input>
          </div>
          <div>
            <el-checkbox v-model="checked"
                         @change="isChecked">短信登录验证</el-checkbox>
            <el-button class="sub"
                       type="primary"
                       size="mini"
                       @click="addPhone">添加手机</el-button>
          </div>

        </div>
      </template>
      <template v-else-if="radio == 'mail'">
        <div class="text">您的邮箱号 ：
          <div class="assistPhoneList-area">
            <div v-for="(item) in mailList"
                 :key="item.mail_id"
                 class="assistPhoneList">
              <span class="mail">{{item.mail}}</span>
              <el-button class="sub"
                         type="danger"
                         size="mini"
                         @click="delMail(item.mail_id)">删除</el-button>
            </div>
          </div>
        </div>
        <div class="inputBox">
          <div class="phoneNumBox">
            <el-input v-model="mailNum"
                      :placeholder="'请输入内容'"></el-input>
          </div>
          <div>
            <!-- <el-checkbox v-model="checked"
                         @change="isChecked"></el-checkbox> -->
            <el-button class="sub"
                       type="primary"
                       size="mini"
                       @click="addMail">添加邮箱</el-button>
          </div>
        </div>
      </template>
    </div>
    <el-dialog :close-on-click-modal="false"
               :close-on-press-escape="false"
               :append-to-body="true"
               :show-close="false"
               :visible="centerDialogVisible"
               width="288px"
               custom-class="successBox"
               center>
      <div style="text-align:center;">手机号添加成功</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   size="mini"
                   @click="refresh">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               :visible="phoneDialogVisible"
               width="288px"
               custom-class="phoneDialog"
               center>
      <div style="padding-left:24px;">主手机号： {{mainPhone}}</div>
      <div class="codeBox">
        <div>
          <el-input class="code_input"
                    size="mini"
                    type="text"
                    v-model="code"
                    placeholder="输入验证码"></el-input>
        </div>
        <el-button type="text"
                   :disabled="codeDisabled"
                   size="mini"
                   @click="getSmsCode">{{codeMsg}}</el-button>

      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   size="mini"
                   @click="verification">验证</el-button>
        <el-button size="mini"
                   @click="phoneDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPhone,
  setPhone,
  deletePhone,
  deleteMail,
  getMail,
  addMail,
} from '@/api/public'
import { smsSendSetting, smsVerifySetting } from '@/api/user'
export default {
  data() {
    return {
      loading: true,
      mainPhone: '',
      main_phone_id: '',
      assistPhone: '',
      assistPhoneList: [],
      phoneNum: '',
      checked: true,
      centerDialogVisible: false,
      code: '',
      phone_id: '',
      phoneDialogVisible: false,
      codeDisabled: false,
      countdown: 60,
      codeMsg: '获取验证码',
      timer: null,

      // 邮箱
      radio: 'phone',
      mailList: [],
      mailNum: '',
      showMail: false,
    }
  },
  computed: {
    loginInfo() {
      return this.$store.state.user.loginInfo
    },
  },
  methods: {
    isChecked(val) {
      if (this.mainPhone == '') {
        this.checked = true
        this.$message.error('第一个手机号必须开启短信登录验证')
        return false
      }
    },
    addPhone() {
      if (this.phoneNum == '') {
        this.$message.error('请输入手机号')
        return
      }
      //   if (!/^1[0-9]{10}$/.test(this.phoneNum)) {
      //     this.$message.error('请输入正确的手机号')
      //     return
      //   }
      let hasVerifySetting = sessionStorage.getItem('hasVerifySetting')
      if (hasVerifySetting || this.mainPhone == '') {
        this.subPhone()
      } else {
        this.phoneDialogVisible = true
      }
    },
    addMail() {
      if (this.mailNum == '') {
        this.$message.error('请输入邮箱号')
        return
      }
      if (!/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(this.mailNum)) {
        this.$message.error('请输入正确的邮箱号')
        return
      }
      addMail({
        mail: this.mailNum,
      }).then((res) => {
        if (res.status == 'ok') {
          getMail()
            .then((res) => {
              const result = res.result
              const mail = result.mail
              if (mail && mail.length > 0) {
                this.mailList = mail
              } else {
              }
            })
            .catch((e) => {})

          this.$message.success('修改成功')
        } else {
          this.$message.error(res.error_msg)
        }
      })
    },
    getPhoneData() {
      this.loading = true
      getPhone()
        .then((res) => {
          this.loading = false
          if (res.status == 'ok') {
            let obj = res.result.find((item) => {
              return item.main == true
            })
            this.mainPhone = obj.phone
            this.main_phone_id = obj.phone_id
            let arr = res.result.filter((item) => {
              return item.main != true
            })
            this.assistPhoneList = arr
          } else {
            console.log(res)
          }
        })
        .catch((e) => {
          this.loading = false
        })
      getMail()
        .then((res) => {
          const result = res.result
          const mail = result.mail
          if (mail && mail.length > 0) {
            this.mailList = mail
          } else {
          }
        })
        .catch((e) => {})
    },
    subPhone() {
      setPhone({
        phone: this.phoneNum,
        is_login_verify: this.checked + '',
      })
        .then((res) => {
          if (res.status == 'ok') {
            if (this.mainPhone != '') {
              this.assistPhoneList.push({
                phone: this.phoneNum,
                phone_id: res.result.phone_id,
              })
            }
            this.centerDialogVisible = true
          } else {
            this.$message.error(res.error_msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.data.error_msg)
        })
    },
    delPhone(phone_id) {
      this.phone_id = phone_id
      let hasVerifySetting = sessionStorage.getItem('hasVerifySetting')
      if (hasVerifySetting) {
        this.$confirm('确认删除?', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          showClose: false,
          closeOnClickModal: false,
        })
          .then(() => {
            deletePhone({
              phone_id: phone_id,
            })
              .then((res) => {
                if (res.status == 'ok') {
                  this.assistPhoneList = this.assistPhoneList.filter((item) => {
                    return !phone_id.includes(item.phone_id)
                  })
                  this.$message.success('删除成功')
                } else {
                  this.$message.error(res.error_msg)
                }
              })
              .catch((e) => {
                this.$message.error(e.data.error_msg)
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      } else {
        this.phoneDialogVisible = true
      }
    },
    delMail(mail_id) {
      this.mail_id = mail_id
      this.$confirm('确认删除?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
      })
        .then(() => {
          deleteMail({
            mail_id: mail_id,
          })
            .then((res) => {
              if (res.status == 'ok') {
                this.mailList = this.mailList.filter((item) => {
                  return !mail_id.includes(item.mail_id)
                })
                this.$message.success('删除成功')
              } else {
                this.$message.error(res.error_msg)
              }
            })
            .catch((e) => {
              this.$message.error(e.data.error_msg)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getSmsCode() {
      if (!this.codeDisabled) {
        this.codeDisabled = true
        smsSendSetting({ phone_id: this.main_phone_id })
          .then((res) => {
            // 验证码60秒倒计时
            if (!this.timer) {
              this.getValidStr()
              this.timer = setInterval(this.getValidStr, 1000)
            }
            if (res.status == 'ok') {
            } else {
              this.$message.warning(res.error_msg)
            }
          })
          .catch((e) => {
            this.codeDisabled = false
          })
          .finally(() => {})
      }
    },
    getValidStr() {
      if (this.countdown > 0 && this.countdown <= 60) {
        this.countdown--
        if (this.countdown !== 0) {
          this.codeMsg = this.countdown + 's'
        } else {
          clearInterval(this.timer)
          this.codeMsg = '获取验证码'
          this.countdown = 60
          this.timer = null
          this.codeDisabled = false
        }
      }
    },
    verification() {
      if (this.code == '') {
        this.$message.warning('请输入验证码')
        return
      }
      smsVerifySetting({ code: this.code, phone_id: this.main_phone_id })
        .then((res) => {
          if (res.status == 'ok') {
            this.phoneDialogVisible = false
            sessionStorage.setItem('hasVerifySetting', true)
            // location.reload()
          } else {
          }
        })
        .catch((e) => {
          // this.$message.error('失败')
        })
    },
    refresh() {
      this.centerDialogVisible = false
      let hasVerifySetting = sessionStorage.getItem('hasVerifySetting')
      if (!hasVerifySetting) {
        location.reload()
      }
    },
  },
  created() {
    this.getPhoneData()
  },
  watch: {
    loginInfo: {
      handler(newVal) {
        if (newVal && newVal.use_mail) {
          if (newVal.use_mail) {
            this.radio = 'mail'
            this.showMail = true
          } else {
            this.radio = 'phone'
            this.showMail = false
          }
        }
      },
      immediate: true,
    },
  },
}
</script>
<style scoped lang='less'>
.views {
  /deep/ .phoneDialog {
    .codeBox {
      display: flex;
      margin-top: 10px;
      padding: 0 24px;
      .code_input {
        margin-right: 12px;
      }
    }
    .el-input__inner {
      width: 128px !important;
      height: 28px !important;
      border: 1px solid #ccc !important;
    }
    .el-dialog__header {
      padding: 0 !important;
    }
    .el-dialog__body {
      padding: 24px 0 20px !important;
    }
    .el-dialog__footer {
      padding-bottom: 24px;
    }
  }
  /deep/ .successBox {
    .el-dialog__header {
      padding: 0 !important;
    }
    .el-dialog__body {
      padding: 24px 0 55px !important;
    }
    .el-dialog__footer {
      padding-bottom: 24px;
    }
  }
}
.setUp {
  padding: 48px;
  width: 100%;
  height: 350px;
  // padding: 0 0 !important;
  box-shadow: 0px 2px 5px 0px rgba(159, 77, 0, 0.2);
  border-radius: 4px 4px 4px 4px;
  background-color: white;
  font-family: MicrosoftYaHei;
  color: #252525;
  .assistPhoneList-area {
    max-height: 80px;
    overflow-y: auto;
    width: 500px;
    .assistPhoneList {
      margin-bottom: 10px;
    }
  }
  .title {
    font-size: 16px;
    margin-bottom: 22px;
  }
  .text {
    font-size: 14px;
    margin-bottom: 12px;
    .phone {
      margin-left: 16px;
      vertical-align: middle;
    }
  }
  .inputBox {
    margin-top: 28px;
    display: flex;
    .phoneNumBox {
      margin-right: 28px;
    }
    /deep/ input {
      width: 230px;
      height: 28px;
    }
  }
  .sub {
    margin-left: 28px;
  }
}
</style>
