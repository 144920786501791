<template>
  <div id="app"
       v-loading="loading ||  errorItem.text=='RepeatRequest'"
       v-message="errorItem"
       ref="echartDiv">
    <v-chart auto-resize
             style="width:100%;height:100%;"
             ref="echart"
             :init-options="{'devicePixelRatio': 2}"
             @legendselectchanged="legendchanged"
             :options="reultOption"
             @click="mouseClick" />
  </div>
</template>

<script>
import {
  setYAxisMaxVal,
  setYAxisMinVal,
  setYAxisMaxVal_1,
  threeDigitsPercentage,
  formatInt,
  setBarMaxVal,
  setBarMinVal,
} from '@/utils'
import { PLANT_TYPE } from '@/views/Detail/components/enume'
import { DateTime } from 'luxon'
import {
  pieOption,
  ddBarOption,
  scatterOption,
  mixyAxisItem,
  lineBarOptionTemp,
  mixOptionTemp,
  seriesItem_dd,
  ddOption,
} from './echartsComps/echartsOptions'
import { parseTime } from './echartsComps/commonFunc'

export default {
  props: {
    pageType: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'pie',
    },
    seriesType: {
      type: String,
      default: 'posts',
    },
    options: {
      type: Object,
      default: () => {
        return pieOption
      },
    },
    getDataMethod: {
      type: Function,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {
        return {}
      },
    },
    mixConfig: {
      type: Array,
      default: () => {
        return []
      },
    },
    ddConfig: {
      type: Array,
      default: () => {
        return []
      },
    },
    transferRawData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    addData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    staticData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isStaticData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorItem: { type: 'info', text: '' },
      loading: false,
      plant_type: PLANT_TYPE,
      data_all: null,
      newOptions: this.options,
      legendData: [],
      seriesData: [] | {},
      xAxisData: [],
      data_raw: null,
      scatterMax: 0,
      paramsState: true,
      selectedData: {},
    }
  },
  methods: {
    mouseClick(event) {
      this.$emit('mouseClick', event)
    },
    legendchanged(e) {
      this.selectedData = e.selected
    },
    getChartData() {
      this.loading = true
      this.$set(this.errorItem, 'type', 'info')
      this.$set(this.errorItem, 'text', '')
      let params = {}
      params = this.params
      if (this.type == 'ddBar') {
        if (this.transferRawData) {
          this.loading = false
          this.seriesData = this.transferRawData
          return
        }
      }
      if (this.staticData && this.isStaticData) {
        this.processingData(this.staticData)
      } else {
        for (const key in params) {
          if (Object.hasOwnProperty.call(params, key)) {
            const element = params[key]
            if (!element) {
              delete params[key]
            }
          }
        }
        this.getDataMethod(params)
          .then((res) => {
            this.processingData(res)
          })
          .catch((e) => {
            console.log(e)
            this.legendData = []
            this.seriesData = []
            this.loading = false
            this.$emit('passTableData', e)
            this.$set(this.errorItem, 'type', 'error')
            this.$set(this.errorItem, 'text', e.backMsg)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    processingData(res) {
      if (res.status == 'ok') {
        if (this.type != 'lineBar') {
          this.loading = false
        }
        if (!res.result && this.type != 'lineBar' && this.type != 'bar') {
          this.legendData = []
          this.seriesData = []
          return
        }
        this.data_all = res.result
        if (this.type == 'pie') {
          this.legendData = Object.keys(
            res.result.pie_chart[this.seriesType]
          ).map((i) => {
            return this.plant_type[i]
          })
          this.seriesData = [res.result.pie_chart[this.seriesType]]
        } else if (this.type == 'smoothedLine') {
          let data = null
          if (this.pageType == 'ebay') {
            data = res.result.word.trend
          } else {
            data = res.result.data
          }
          this.xAxisData = data.reverse().map((item) => {
            return parseTime(item.ts, '{y}-{m}')
          })
          let datas = {}
          Object.keys(data[0]).forEach((key) => {
            if (key != 'ts') {
              datas[key] = data.map((x) => {
                return x[key]
              })
            }
          })
          this.seriesData = datas
        } else if (this.type == 'scatter') {
          let self = this
          let data = res.result.data.map((item) => {
            return this.mixConfig.map((item2) => {
              return item[item2.name]
            })
          })
          data.forEach(function (item, index) {
            item.forEach(function (d, i) {
              if (item[2] == 0) {
                item[1] = 100
                self.scatterMax = 100
              } else if (item[1] > self.scatterMax) {
                self.scatterMax = item[1]
                  ? formatInt(item[1], (Math.round(item[1]) + '').length - 2) *
                    2
                  : 100
              }
            })
          })
          data.forEach(function (item, index) {
            item.forEach(function (d, i) {
              if (item[2] == 0) {
                item[1] = self.scatterMax
              }
            })
          })
          this.seriesData = data
        } else if (this.type == 'lineBar') {
          this.data_raw = res.data
          if (this.mixConfig && this.mixConfig.length > 0) {
            if (
              this.pageType == 'fenxi_trend' ||
              this.pageType == 'listern_trend'
            ) {
              this.xAxisData = res.data.map((item) => {
                return item.ts
              })
              // .reverse()
            } else {
              this.xAxisData = res.data.reverse().map((item) => {
                return parseTime(item.ts, '{y}-{m}')
              })
            }

            let datas = {}
            Object.keys(res.data[0]).forEach((key) => {
              if (key != 'ts') {
                datas[key] = res.data.map((x) => {
                  return x[key]
                })
              }
            })
            let newAddDatas = {}
            Object.keys(this.addData).forEach((key) => {
              newAddDatas[key] = this.addData[key]
            })
            let das = Object.assign(datas, newAddDatas)
            this.seriesData = []
            this.seriesData = das
            this.paramsState = true
            this.loading = false
          } else {
            return
          }
        } else if (this.type == 'mix') {
          if (this.mixConfig && this.mixConfig.length > 0) {
            this.xAxisData = res.result[this.mixConfig[0].name].map((item) => {
              return parseTime(item[0], '{y}-{m}-{d}')
            })
            this.seriesData = res.result
          } else {
            return
          }
        } else if (this.type == 'dd') {
          if (this.ddConfig && this.ddConfig.length > 0) {
            this.xAxisData = res.result[this.ddConfig[0].name].map((item) => {
              return parseTime(item[0], '{y}-{m}-{d}')
            })
            this.seriesData = res.result
          } else {
            return
          }
        } else if (this.type == 'bar') {
          this.data_raw = res.data
          if (this.mixConfig && this.mixConfig.length > 0) {
            this.xAxisData = res.data.map((item) => {
              return parseTime(item.ts, '{y}-{m}')
            })
            let datas = {}
            Object.keys(res.data[0]).forEach((key) => {
              if (key != 'ts') {
                datas[key] = res.data.map((x) => {
                  return x[key]
                })
              }
            })
            let newAddDatas = {}
            Object.keys(this.addData).forEach((key) => {
              newAddDatas[key] = this.addData[key]
            })
            let das = Object.assign(datas, newAddDatas)
            this.seriesData = []
            this.seriesData = das
            this.paramsState = true
            this.loading = false
          } else {
            return
          }
        } else if (this.type == 'priceBar') {
          if (this.mixConfig && this.mixConfig.length > 0) {
            this.xAxisData = res.result.xAxisData
            this.seriesData = res.result.seriesData[this.mixConfig[0].name]
          }
        }
        if (this.type == 'lineBar') {
          this.$emit('passTableData', res)
        } else {
          this.$emit('passTableData', res.result)
        }
        this.$set(this.errorItem, 'type', 'info')
        this.$set(this.errorItem, 'text', '')
      } else {
        this.$emit('passTableData', res)
        this.$set(this.errorItem, 'type', 'warning')
        this.$set(this.errorItem, 'text', res.backMsg)
      }
    },
    //将接口数据格式转为series中需要的数据格式
    formateData(data, isPercent) {
      if (data && data.length) {
        let data_out = data.map((item) => {
          let item_new = {}
          item_new.name = parseTime(item[0], '{y}-{m}-{d}')
          if (isPercent) {
            item_new.value = Number(item[1] * 100).toFixed(1)
          } else {
            item_new.value = item[1]
          }

          return item_new
        })
        return data_out
      } else {
        return []
      }
    },
  },
  computed: {
    /*newOptions() {
      switch (this.type) {
        case 'pie':
          return pieOption
        case 'mix':
          return mixOptionTemp
      }
    },*/
    reultOption() {
      let options = _.cloneDeep(this.newOptions)
      options.tooltip.formatter = this.newOptions.tooltip.formatter
      if (this.type == 'pie') {
        if (options.legend && this.legendData && this.legendData.length > 0) {
          options.legend.data = JSON.parse(JSON.stringify(this.legendData))
        }
        if (this.seriesData[0] && this.seriesData[0] !== {}) {
          for (var i = 0; i < Object.keys(this.seriesData[0]).length; i++) {
            let key = Object.keys(this.seriesData[0])[i]
            let item = {}
            if (this.seriesData[0][key] != 0) {
              item.name = this.plant_type[key]
              item.value = this.seriesData[0][key]
              options.series[0].data.push(item)
            }
          }
        }
        if (
          this.seriesData[0] &&
          Object.values(this.seriesData[0]).every((item) => item == 0)
        ) {
          options.title = {
            text: '暂无数据',
            x: 'center',
            y: 'center',
            textStyle: {
              color: '#333',
              fontWeight: 'normal',
              fontSize: 14,
            },
          }
        }
      }
      if (this.type == 'ddBar') {
        if (this.seriesData && Object.keys(this.seriesData).length > 0) {
          this.mixConfig.map((item) => {
            let seriesItem = {
              name: item.label,
              data: [this.seriesData[item.name]],
              type: 'bar',
              stack: 'all',
              label: {
                show: true,
                position: 'insideRight',
                fontSize: 18,
              },
            }
            options.series.push(seriesItem)
            options.xAxis.max = this.mixConfig[0].xAxisMax
            if (this.mixConfig[0].name == 'neg_percentage') {
              options.color = [options.color[options.color.length - 1]]
            } else if (this.mixConfig[0].name == 'neg') {
              options.color = [options.color[1]]
            } else if (this.mixConfig[0].name == 'neu') {
              options.color = [options.color[2]]
            }
          })
        }
      } else if (this.type == 'smoothedLine') {
        options.xAxis.data = this.xAxisData
        options.yAxis.axisLabel.formatter = function (value) {
          if (value >= 10000) {
            if (value / 10000 >= 10000)
              return Math.ceil(value / 10000 / 10000) + '亿'
            return Math.ceil(value / 10000) + '万'
          }
          return value
        }
        this.mixConfig.map((item) => {
          let seriesItem = {
            name: item.label,
            data: this.seriesData[item.name],
            type: item.type,
            smooth: true,
            lineStyle: {
              width: 2,
              color: item.axisLabelColor,
            },
          }
          options.series.push(seriesItem)
        })
      } else if (this.type == 'scatter') {
        let self = this
        options.xAxis.axisLabel.formatter = function (value) {
          if (value >= 10000) {
            if (value / 10000 >= 10000) return value / 10000 / 10000 + '亿'
            return value / 10000 + '万'
          }
          return value
        }
        options.yAxis.axisLabel.fontSize = 16
        if (self.scatterMax > 100) {
          options.yAxis.max = self.scatterMax
        }
        options.yAxis.axisLabel.formatter = function (value) {
          if (value == self.scatterMax) {
            return '∞'
          }
          if (value > self.scatterMax) {
            return ''
          }
          var text = Math.round(value * 100) + '%'
          return text
        }
        options.series[0].data = this.seriesData
      } else if (this.type == 'lineBar') {
        if (options.legend && this.mixConfig && this.mixConfig.length) {
          options.legend.data = JSON.parse(JSON.stringify(this.mixConfig)).map(
            (item) => {
              return item.label
            }
          )
          options.xAxis[0].data = this.xAxisData
          this.mixConfig.map((item, index) => {
            if (item.asyAxis) {
              let yAxisItem = JSON.parse(JSON.stringify(mixyAxisItem))
              yAxisItem.show = item.show === false ? false : true
              yAxisItem.name = item.label
              yAxisItem.position = item.yAxisPosition
              yAxisItem.offset = item.yAxisOffset
              if (this.seriesData[item.name]) {
                if (this.pageType == 'fenxi_trend') {
                  options.xAxis[0].boundaryGap = false
                  let setYAxisMaxVal = function (arr) {
                    const max = Math.ceil(Math.max(...arr))
                    const length = max.toString().length

                    let maxValue = 0
                    for (let i = 3; i <= 12; i = i + 3) {
                      const temp = i * 10 ** (length - 1)
                      if (max < temp) {
                        maxValue = temp
                        break
                      }
                    }
                    return maxValue
                  }
                  function setYAxisMinVal(arr) {
                    const min = Math.min(...arr)
                    const minVal = Math.floor(min - min / 2)
                    return minVal < 0 ? 0 : minVal
                  }
                  yAxisItem.max = setYAxisMaxVal(this.seriesData[item.name])
                  yAxisItem.interval = yAxisItem.max / 3
                } else {
                  yAxisItem.max = setYAxisMaxVal(this.seriesData[item.name])
                }
                // yAxisItem.min = setMinVal(this.seriesData[item.name])
                // yAxisItem.min = setYAxisMinVal(this.seriesData[item.name],true)

                if (this.pageType == 'fenxi_trend') {
                  // yAxisItem.interval = yAxisItem.max / 3
                } else {
                  yAxisItem.interval = yAxisItem.max / 5
                }
              } else {
                yAxisItem.max = item.yAxisMax
                // yAxisItem.min = item.yAxisMin
                yAxisItem.interval = item.yAxisInterval
              }
              if (item.isPercent) {
                yAxisItem.axisLabel.formatter = '{value} %'
              }
              yAxisItem.axisLabel.formatter = function (value) {
                if (localStorage.getItem('language') == 'en') {
                  if (value >= 1000) {
                    if (value / 1000 >= 1000)
                      return Math.ceil(value / 1000 / 1000) + 'M'
                    return Math.ceil(value / 10000) + 'K'
                  }
                } else {
                  if (value >= 10000) {
                    if (value / 10000 >= 10000)
                      return Math.ceil(value / 10000 / 10000) + '亿'
                    return Math.ceil(value / 10000) + '万'
                  }
                }

                return value
              }
              yAxisItem.axisLabel.color = item.axisLabelColor
              if (item.axisLabelAlign) {
                yAxisItem.axisLabel.align = item.axisLabelAlign
              }
              yAxisItem.axisLine.lineStyle.color = item.axisLabelColor
              yAxisItem.axisLine.show = item.showAxisLine
              yAxisItem.axisTick.show = item.showAxisLine
              if (item.showAxisLine) {
                yAxisItem.splitLine = {
                  lineStyle: {
                    color: '#eeeeee',
                  },
                }
              }
              options.yAxis.push(yAxisItem)
            } else {
              if (this.pageType == 'fenxi_trend') {
                if (
                  this.seriesData[item.name] &&
                  options.yAxis[index] &&
                  !item.isPercent
                ) {
                  const yAxisItem = options.yAxis[index]
                  let setYAxisMaxVal = function (arr) {
                    const max = Math.ceil(Math.max(...arr))
                    const length = max.toString().length

                    let maxValue = 0
                    for (let i = 3; i <= 12; i = i + 3) {
                      const temp = i * 10 ** (length - 1)
                      if (max < temp) {
                        maxValue = temp
                        break
                      }
                    }
                    return maxValue
                  }
                  if (item.splitNumber) {
                    options.yAxis[index].splitNumber = item.splitNumber
                  } else {
                    yAxisItem.max = setYAxisMaxVal(this.seriesData[item.name])
                    yAxisItem.interval = yAxisItem.max / 3
                  }
                } else if (options.yAxis[index] && item.isPercent) {
                  options.yAxis[index].splitNumber = 3
                }
              }
            }
            let seriesItem = {
              name: item.label,
              data: this.seriesData[item.name],
              type: item.type,
              smooth: true,
              lineStyle: {
                width: 2,
                color: item.axisLabelColor,
              },
              yAxisIndex: item.yAxisIndex,
            }
            if (item.stack) {
              seriesItem.stack = item.stack
            }
            if (item.barMaxWidth) {
              seriesItem.barMaxWidth = item.barMaxWidth
            }
            if (item.stack) {
              seriesItem.stack = item.stack
            }
            options.series.push(seriesItem)
          })
        }
        // xAxisData
      } else if (this.type == 'mix') {
        if (options.legend && this.mixConfig && this.mixConfig.length) {
          options.legend.data = JSON.parse(JSON.stringify(this.mixConfig)).map(
            (item) => {
              return item.label
            }
          )
          options.xAxis[0].data = this.xAxisData
          this.mixConfig.map((item) => {
            if (item.asyAxis) {
              let yAxisItem = JSON.parse(JSON.stringify(mixyAxisItem))
              yAxisItem.name = item.label
              yAxisItem.position = item.yAxisPosition
              yAxisItem.offset = item.yAxisOffset
              if (item.isPercent) {
                yAxisItem.axisLabel.formatter = '{value} %'
              }
              yAxisItem.axisLine.show = item.showAxisLine
              yAxisItem.axisTick.show = item.showAxisLine
              if (item.showAxisLine) {
                yAxisItem.splitLine = {
                  lineStyle: {
                    color: '#eeeeee',
                  },
                }
              }
              options.yAxis.push(yAxisItem)
            }
            let seriesItem = {
              name: item.label,
              data: this.formateData(
                this.seriesData[item.name],
                item.isPercent
              ),
              type: item.type,
              lineStyle: {
                width: 2,
                color: '#088DFF',
              },
              yAxisIndex: item.yAxisIndex,
            }
            options.series.push(seriesItem)
          })
        }
        // xAxisData
      } else if (this.type == 'dd') {
        if (options.legend && this.ddConfig && this.ddConfig.length) {
          options.legend.data = JSON.parse(JSON.stringify(this.ddConfig)).map(
            (item) => {
              return item.title
            }
          )
          options.xAxis.data = this.xAxisData
          this.ddConfig.map((item) => {
            let seriesItem = JSON.parse(JSON.stringify(seriesItem_dd))
            seriesItem.name = item.title
            if (this.seriesData[item.name]) {
              seriesItem.data = this.seriesData[item.name].map((item) => {
                return item[1]
              })
            }
            options.series.push(seriesItem)
          })
        }
      } else if (this.type == 'bar') {
        if (options.legend && this.mixConfig && this.mixConfig.length) {
          options.legend.data = JSON.parse(JSON.stringify(this.mixConfig)).map(
            (item) => {
              return item.label
            }
          )
          options.xAxis[0].data = this.xAxisData
          this.mixConfig.forEach((item) => {
            if (item.asyAxis) {
              let yAxisItem = JSON.parse(JSON.stringify(mixyAxisItem))
              yAxisItem.show = item.show === false ? false : true
              yAxisItem.name = item.label
              yAxisItem.position = item.yAxisPosition
              yAxisItem.offset = item.yAxisOffset

              if (this.seriesData[item.name]) {
                // yAxisItem.max = setYAxisMaxVal_1(this.seriesData)
                // yAxisItem.min = setYAxisMinVal(this.seriesData)
                yAxisItem.max = setBarMaxVal(
                  this.seriesData[item.name].concat(
                    this.seriesData[item.seriesName]
                  )
                )
                yAxisItem.min = setBarMinVal(
                  this.seriesData[item.name].concat(
                    this.seriesData[item.seriesName]
                  )
                )
                // yAxisItem.interval = (yAxisItem.max - yAxisItem.min) / 3
                if (yAxisItem.max == 0 || yAxisItem.min == 0) {
                  yAxisItem.interval = (yAxisItem.max - yAxisItem.min) / 3
                } else {
                  yAxisItem.maxInterval = Math.max(
                    Math.abs(yAxisItem.max / 3),
                    Math.abs(yAxisItem.min / 3)
                  )
                  yAxisItem.minInterval = Math.max(
                    Math.abs(yAxisItem.max / 3),
                    Math.abs(yAxisItem.min / 3)
                  )
                }
              } else {
                yAxisItem.max = item.yAxisMax
                // yAxisItem.min = item.yAxisMin
                yAxisItem.interval = item.yAxisInterval
              }
              if (item.isPercent) {
                yAxisItem.axisLabel.formatter = function (value) {
                  return value.toFixed() + '%'
                }
              } else {
                yAxisItem.axisLabel.formatter = function (value) {
                  if (value >= 10000) {
                    if (value / 10000 >= 10000)
                      return Math.ceil(value / 10000 / 10000) + '亿'
                    return Math.ceil(value / 10000) + '万'
                  }
                  return value
                }
              }

              if (item.showAxisLine) {
                yAxisItem.splitLine = {
                  lineStyle: {
                    color: '#eeeeee',
                  },
                }
              }
              options.yAxis.push(yAxisItem)
            }
            let seriesItem = {
              name: item.label,
              data: this.seriesData[item.name]
                ? this.seriesData[item.name].map((item) => {
                    return parseFloat(item * 100).toFixed(1)
                  })
                : this.seriesData[item.name],
              type: item.type,
              smooth: true,
              lineStyle: {
                width: 2,
                color: item.axisLabelColor,
              },
              yAxisIndex: item.yAxisIndex,
              barMaxWidth: item.barMaxWidth ? item.barMaxWidth : 100,
            }
            options.series.push(seriesItem)
          })
        }
      } else if (this.type == 'priceBar') {
        let maxValue = _.max(this.seriesData)
        // let max = formatInt(maxValue, (Math.round(maxValue) + '').length - 2)
        // options.yAxis.max = max
        // options.yAxis.interval = max / 4
        options.yAxis.minInterval = 1
        options.xAxis.data = this.xAxisData
        let name = this.mixConfig[0].name
        if (this.mixConfig[0].asyAxis) {
          options.yAxis.axisLabel.formatter = function (value) {
            if (value >= 10000) {
              if (value / 10000 >= 10000) return value / 10000 / 10000 + '亿'
              return value / 10000 + '万'
            }
            if (name == 'ratio') {
              return value + '%'
            }
            return value
          }
        }

        this.mixConfig.forEach((item) => {
          let seriesItem = {
            name: item.label,
            data: this.seriesData,
            barWidth: '100%',
            type: item.type,
            backgroundStyle: {
              color: options.color[0],
            },
          }
          options.series.push(seriesItem)
        })
      }
      if (this.type == 'bar') {
        let length = 0
        options.series.forEach((item) => {
          if (
            item.data &&
            item.data.filter((ele) => isNaN(Number(ele))).length ==
              item.data.length
          ) {
            length++
          }
        })
        if (length == options.series.length) {
          options.title = {
            text: '暂无数据',
            x: 'center',
            y: 'center',
            textStyle: {
              color: '#ccc',
              fontWeight: 'normal',
              fill: '#9d9d9d',
              fontWeight: 'bold',
              text: '暂无数据',
              fontFamily: 'Microsoft YaHei',
              fontSize: '25px',
            },
          }
        }
      }
      return options
    },
    newParams() {
      return this.params
    },
  },
  watch: {
    type: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'pie':
              //  this.getChartData()
              this.newOptions = { ...pieOption, ...this.options }
              break
            case 'smoothedLine':
              this.newOptions = { ...this.options }
              break
            case 'scatter':
              this.newOptions = { ...scatterOption, ...this.options }
              break
            case 'lineBar':
              this.newOptions = { ...lineBarOptionTemp, ...this.options }
              break
            case 'ddBar':
              this.newOptions = { ...ddBarOption, ...this.options }
              break
            case 'mix':
              this.newOptions = { ...mixOptionTemp, ...this.options }
              break
            case 'dd':
              this.newOptions = { ...ddOption, ...this.options }
              break
            case 'bar':
              this.newOptions = { ...this.options }
              break
            case 'priceBar':
              this.newOptions = { ...this.options }
              break
            default:
              this.newOptions = {}
              break
          }
        }
      },
      immediate: true,
    },
    seriesType: {
      handler(newVal, oldVal) {
        if (this.data_all) {
          this.legendData = Object.keys(this.data_all.pie_chart[newVal]).map(
            (i) => {
              return this.plant_type[i]
            }
          )
          if (this.type == 'pie') {
            this.newOptions = { ...pieOption, ...this.options }
          }
          this.seriesData = [this.data_all.pie_chart[newVal]]
        }
      },
      immediate: true,
    },
    newParams: {
      handler(newVal, oldVal) {
        this.paramsState = false //因为linbar里面的数据是由接口和addData数据组合显示的，当没有发起请求时只是addData数据变更时就要变量是来控制
        if (this.staticData && this.isStaticData) {
          //如果有传过来的staticData就不用重复调用getChartData
          return
        }
        this.getChartData()
      },
      deep: true,
    },
    staticData: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getChartData()
        }
      },
      deep: true,
    },
    addData: {
      handler(newVal, oldVal) {
        if (this.data_raw && this.paramsState) {
          let datas = {}
          Object.keys(this.data_raw[0]).forEach((key) => {
            if (key != 'ts') {
              datas[key] = this.data_raw.map((x) => {
                return x[key]
              })
            }
          })
          let newAddDatas = {}
          Object.keys(newVal).forEach((key) => {
            newAddDatas[key] = newVal[key]
          })
          let newDatas = Object.assign(datas, newAddDatas)
          let reverseData = {}
          Object.keys(newDatas).forEach((key) => {
            reverseData[key] = newDatas[key]
          })
          if (
            this.type == 'lineBar' &&
            Object.keys(this.selectedData).length != 0
          ) {
            this.newOptions.legend.selected = this.selectedData
          }
          this.seriesData = reverseData
        }
      },
    },
  },
  mounted() {
    this.getChartData()
    // const that = this
    // window.onresize = () => {
    //   // 根据窗口大小调整图表大小
    //   if (this.$refs.echart) {
    //     this.$refs.echart.resize()
    //   }
    // }
  },
}
</script>

<style scoped lang="less">
#app {
  height: 100%;
  width: 100%;
  .echarts {
    width: 100%;
    height: 100%;
    div {
      //   width: 100%;
      //   height: auto;
      //   overflow: auto !important;
      canvas {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
