<template>
  <div class="echarts-new"
       v-loading="loading">
    <div class="icons">
      <i class="iconfont icon-xiazai"
         title="下载为图片"
         @click="downloadChartPng"
         v-show="download"></i>
      <i class="iconfont icon-fullscreen"
         title="放大查看"
         @click="fullScreenHandler()"
         v-show="fullScreen"></i>
    </div>
    <v-chart-new :option="options"
                 ref="VChartNew"
                 style="width: 100%;height: 100%"
                 @legendselectchanged="legendselectchanged"
                 @click="mouseClick"
                 @geoselectchanged="geoselectchanged"
                 @geoselected="geoselected"
                 @geounselected="geounselected"
                 @zr:click="zrClick"
                 :autoresize="true"></v-chart-new>
    <el-dialog :visible.sync="showFullScreen"
               :show-close="false"
               center
               append-to-body>
      <div ref="fullChart"
           :id="'fullChart' + Math.random()">
        <v-chart-new :option="options"
                     style="width: 100%;height: 100%"
                     :autoresize="true"></v-chart-new>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import VChartNew from 'vue-echarts6'
// 必需: vue-echarts 组件内部会调用echarts包.
import * as echarts from 'echarts'
import { ref, reactive } from '@vue/composition-api'
import { mapJson } from './echartsComps/mapGeoJson'
// 图表水印配置
const waterMarkOption = {
  type: 'text',
  silent: true,
  left: '0',
  bottom: '0',
  z: 100,
  style: {
    fill: '#999',
    text: '数据来源: 魔镜市场情报',
    font: 'bolder "Microsoft YaHei", sans-serif',
    fontSize: 14,
  },
}
export default {
  name: 'EchartsNew',
  components: {
    VChartNew,
  },
  props: {
    mapName: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    download: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    name: {
      // 图表名称
      type: String,
      default: 'chart',
    },
    waterMark: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    waterMarkText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showFullScreen: false,
    }
  },
  computed: {},
  methods: {
    legendselectchanged(value) {
      this.$emit('legendselectchanged', value)
    },
    mouseClick(event) {
      this.$emit('mouseClick', event)
    },
    geoselectchanged(value) {
      this.$emit('geoselectchanged', value)
    },
    geoselected(value) {
      this.$emit('geoselected', value)
    },
    geounselected(value) {
      this.$emit('geounselected', value)
    },
    zrClick(event) {
      this.$emit('zrClick', event)
    },
    downloadChartPng() {
      let content = this.$refs.VChartNew.chart.getDataURL()
      this.chartDownloadToPng(this.name + '.png', content)
    },
    chartDownloadToPng(fileName, content) {
      let aLink = document.createElement('a')
      let blob = this.base64ToBlob(content) // new Blob([content]);
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)
      document.body.appendChild(aLink)
      aLink.click()
      document.body.removeChild(aLink)
    },
    base64ToBlob(code) {
      let parts = code.split(';base64,')
      let contentType = parts[0].split(':')[1]
      let raw = window.atob(parts[1]) // 解码base64得到二进制字符串
      let rawLength = raw.length
      let uInt8Array = new Uint8Array(rawLength) // 创建8位无符号整数值的类型化数组
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i) // 数组接收二进制字符串
      }
      return new Blob([uInt8Array], { type: contentType })
    },
    fullScreenHandler() {
      this.showFullScreen = true
      this.$nextTick(() => {
        this.$refs.fullChart.style.height = window.innerHeight / 2 + 'px'
      })
    },
    addWaterMark(option) {
      if (option.graphic && option.graphic.length > 0) {
      } else {
        option.graphic = []
      }
      option.graphic.push(this.waterMarkOptionReactive)
    },
  },
  created() {
    if (this.mapName) {
      echarts.registerMap(this.mapName, mapJson[this.mapName])
    }
  },
  mounted() {},
  setup(props, contexts) {
    let waterMarkOptionReactive = reactive(waterMarkOption)
    if (props.waterMark && props.waterMarkText) {
      waterMarkOptionReactive.style.text = props.waterMarkText
    }
    return {
      waterMarkOptionReactive,
    }
  },
  watch: {
    options(newVal, oldVal) {
      if (this.waterMark == true) {
        this.addWaterMark(newVal)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.echarts-new {
  width: 100%;
  height: 100%;
  position: relative;

  .icons {
    position: absolute;
    top: -25px;
    right: 0;
    height: 25px;
    line-height: 25px;
    display: flex;

    .icon-xiazai {
      font-size: 20px;
      color: #3a63f3;
      cursor: pointer;
    }
    .icon-fullscreen {
      font-size: 25px;
      color: #3a63f3;
      cursor: pointer;
    }
  }
}
</style>
