<template>
  <el-container class="outer-container"
                v-if="showCpn"
                :class="$i18n.locale == 'en' ? 'en-font' : ''">
    <el-header class="outheader">
      <TopHeader v-if="showTopHeader">
      </TopHeader>
    </el-header>
    <el-container class="inner-container"
                  v-if="showContain">
      <el-aside class="inner-aside">
        <asideMenue></asideMenue>
      </el-aside>
      <el-container class="inner-container2">
        <!-- <HeadProduct v-if="!$store.state.user.loginInfo.is_partner"></HeadProduct> -->
        <el-header class="header">
          <template v-if="(noticeMsgArr.length > 0)">
            <el-alert v-for="(item,index) in noticeMsgArr"
                      :key="item + index"
                      :title="item"
                      :closable="false"
                      :style="{width: 'calc(100% - 32px)',marginTop: '10px',marginBottom: '10px',marginLeft: '20px'}"
                      type="warning"
                      effect="dark">
            </el-alert>
          </template>

          <MainHeader v-if="showMainHeader">
          </MainHeader>
          <ItemDetailHeader v-if="showItemHeader">
          </ItemDetailHeader>
        </el-header>
        <el-main>
          <el-scrollbar style="height:100%"
                        ref="scrollbar">
            <div style="width:100%">
              <Filters v-if="showFilter"></Filters>
              <router-view class="roterView" />
              <Footer v-if="!$store.state.user.loginInfo.is_partner">
              </Footer>
            </div>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
    <!-- <ChatRobot :wsUrl="wsUrl"
               :loginInfo="loginInfo"
               :isShow="showChatBot"
               :isCreated="createdChatBot"
               @changeShowChatBot="changeShowChatBot"></ChatRobot>
    <div class="wakeUpRobot"
         v-if="createdChatBot"
         @click.stop="changeShowChatBot()"><i class="iconfont icon-liuyanjianyi"></i>
    </div> -->
  </el-container>
</template>

<script>
import { ChatRobot } from 'common'
import asideMenue from '@/components/AsideMenue.vue'
import TopHeader from '@/components/topHeader.vue'
import MainHeader from '@/components/mainHeader.vue'
// import HeadProduct from '@/components/headProduct.vue'
import ItemDetailHeader from '@/components/itemDetailHeader.vue'
import Footer from '@/components/footer.vue'
import Filters from '@/components/filters.vue'
import { getNoticeInfo } from '@/api/public'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { getObjFromUrl } from '@/utils'
export default {
  components: {
    ChatRobot,
    asideMenue,
    TopHeader,
    Footer,
    MainHeader,
    // HeadProduct,
    ItemDetailHeader,
    Filters,
  },
  data() {
    return {
      showContain: true,
      showNotice: false,
      noticeMsgArr: [],
      wsUrl: 'wss://marketbot.mktindex.com/ws/copilot',
      showCpn: false,
    }
  },
  computed: {
    loginInfo() {
      return this.$store.state.user.loginInfo
    },
    createdChatBot() {
      return this.$store.state.user.createdChatBot
    },
    showChatBot() {
      return this.$store.state.user.showChatBot
    },
    showFilter() {
      return this.$store.state.user.showFilter
    },
    showMainHeader() {
      return this.$store.state.user.SHowMainHead
    },
    showItemHeader() {
      return this.$store.state.user.showItemHead
    },
    showTopHeader() {
      return this.$store.state.user.SHowTopHead
    },
    filterParams() {
      return this.$store.state.user.filterParams
    },
  },
  watch: {
    filterParams(newVal, oldVal) {
      if (newVal.comment_url_id) {
        this.getNoticeInfoMethod({
          comment_url_id: newVal.comment_url_id,
        })
      }
    },
  },
  mounted() {
    this.$eventBus.$on('TopScroll', () => {
      this.$refs.scrollbar.wrap.scrollTop = 0
    })
  },
  methods: {
    changeShowChatBot() {
      this.$store.dispatch(
        'user/changeShowChatBot',
        !this.$store.state.user.showChatBot
      )
    },
    changeJdVersion(val) {
      this.showContain = false
      this.$nextTick(() => {
        this.showContain = true
      })
    },
    getNoticeInfoMethod(params) {
      getNoticeInfo(params).then((res) => {
        if (res.result && res.result.length > 0) {
          this.showNotice = true
          const language = localStorage.getItem('language')
          this.noticeMsgArr = res.result.map((item) => {
            if (language == 'en' && item.msg_en) {
              return item.msg_en
            } else {
              return item.msg
            }
          })
        } else {
          this.showNotice = false
          this.noticeMsgArr = []
        }
      })
    },
  },
  async created() {
    this.$eventBus.$on('jdVersionChange', this.changeJdVersion)
    /**
     * {
     *    result: [],
     *    status: "ok"
     * }
     *
     */
    const browserFinger = localStorage.getItem('browserFinger')
    if (!browserFinger) {
      this.showCpn = false
      const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get()
      console.log("container", result, result.visitorId);
      localStorage.setItem('browserFinger', result.visitorId)
      this.showCpn = true
    } else {
      this.showCpn = true
    }
    this.getNoticeInfoMethod({})
  },
  beforeDestroyed() {
    this.$eventBus.$off('jdVersionChange', this.changeJdVersion)
    this.$eventBus.$off('moveScroll')
  },
}
</script>

<style scoped lang="less">
.wakeUpRobot {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 50px;
  height: 50px;
  line-height: 54px;
  border-radius: 50%;
  text-align: center;
  background: linear-gradient(to bottom right, #5a88fd, #00cebc);
  z-index: 10000;
  i {
    color: #fff;
    font-size: 20px;
  }
}
</style>
