//import Cookies from 'js-cookie'

const state = {
	lang: 'zh', //getToken(),
	// name: '',
	// avatar: '',
	// introduction: '',
	// roles: [],
}

const mutations = {
	SET_Lang: (state, data) => {
		state.lang = data
	},
}

const actions = {
	SETLangState({ commit }, view) {
		commit('SET_Lang', view)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
