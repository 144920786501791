<template>
  <div ref="lazyContent">
    <div v-if="isLazyVisible">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lazyCpn',
  props: ['lazyFuc'],
  data() {
    return {
      isLazyVisible: false,
      lazyLoadObser: null,
    }
  },
  mounted() {
    this.$eventBus.$on('resetLazy', (val) => {
      this.resetLazy()
    })
    this.initLazy()
  },
  beforeDestroyed() {
    this.$eventBus.$off('resetLazy')
  },
  methods: {
    initLazy() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isLazyVisible = true
            if (this.lazyFuc) {
              this.lazyFuc()
            }
            observer.unobserve(this.$refs.lazyContent)
          }
        })
      })
      if (this.$refs.lazyContent) {
        observer.observe(this.$refs.lazyContent)
        this.lazyLoadObser = observer
      }
    },
    resetLazy() {
      this.isLazyVisible = false
      this.initLazy()
    },
  },
}
</script>