import Vue from "vue";
Vue.directive("message", {
  bind: function (el, binding, vnode) {},
  update: function (el, binding, vnode, oldVnode) {
    // el.focus();
    var s = JSON.stringify;
    if (
      binding.value &&
      binding.value.text &&
      binding.value.text != "RepeatRequest"
    ) {
      let a = null;
      if (
        el.parentElement.getElementsByClassName("msg_div") &&
        el.parentElement.getElementsByClassName("msg_div").length
      ) {
        a = el.parentElement.getElementsByClassName("msg_div")[0];
      } else {
        a = document.createElement("div");
        a.className = "msg_div";
        el.parentElement.insertBefore(a, el);
        //  el.parentElement.style["overflow-y"] = "auto";
        el.style.height = "calc(100% - 64px)";
      }
      a.parentElement.style["flex-wrap"] = "wrap";
      a.innerHTML =
        "<div class='msg_ msg_" +
        binding.value.type +
        "'>" +
        binding.value.text +
        "</div>";
      //  el.style.display = "none";
    } else {
      if (
        el.parentElement.getElementsByClassName("msg_div") &&
        el.parentElement.getElementsByClassName("msg_div").length
      ) {
        for (
          let i = 0;
          i < el.parentElement.getElementsByClassName("msg_div").length;
          i++
        ) {
          el.parentElement.removeChild(
            el.parentElement.getElementsByClassName("msg_div")[i]
          );
        }
      }
      // el.style.display = "block";
    }
  },
  componentUpdated: function (el, binding, vnode, oldVnode) {
    if (el.children[0] && el.children[0].classList.contains("echarts")) {
      setTimeout(() => {
        el.children[0].__vue__.chart.resize(el.style.width, el.style.height);
      }, 0);
    }
  },
});
