<template>
  <span>{{number}}</span>

</template>

<script>
export default {
  name: 'NumberToCurrencyNo',
  props: { value: 0 },
  data() {
    return {}
  },
  computed: {
    number() {
      let value = this.value
      if (!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },
  },
}
</script>

<style>
</style>